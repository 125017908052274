import { ApolloError, getApiProjectId } from "@lumar/shared";
import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import { validateDatasourceConnectionFilter } from "../../../_common/connection-filtering/filter-or-rule-field-array/validateDatasourceConnectionFilterOutput";
import {
  GetSegmentsListQuery,
  ModuleCode,
  useGetSegmentsListQuery,
} from "../../../graphql";

export interface SegmentsListData {
  segments: (NonNullable<
    NonNullable<GetSegmentsListQuery["getProject"]>["segments"]
  >["edges"][0]["node"] & {
    hasActiveTask: boolean;
    isSupportedFormat: boolean;
    isGenerating: boolean;
    isFailed: boolean;
    report?: NonNullable<
      GetSegmentsListQuery["getProject"]
    >["crawls"]["nodes"][0]["reports"]["edges"][0]["node"];
  })[];
  crawl:
    | NonNullable<GetSegmentsListQuery["getProject"]>["crawls"]["nodes"][0]
    | undefined;
  maxSegmentCount: number;
  moduleCode: ModuleCode;
}

export interface SegmentsListResult {
  data: SegmentsListData;
  loading: boolean;
  error: ApolloError | undefined;
}

export function useSegmentsList(): SegmentsListResult {
  const { projectId } = useParams<{ projectId: string }>();

  const {
    data: queryData,
    loading: queryLoading,
    error,
    fetchMore,
    stopPolling,
  } = useGetSegmentsListQuery({
    variables: { projectId },
    fetchPolicy: "cache-first",
    pollInterval: 20000,
    onError: () => {
      stopPolling();
    },
  });

  const dataInner = useRef<GetSegmentsListQuery | undefined>(undefined);

  const segmentPageInfo = queryData?.getProject?.segments?.pageInfo;
  const tasksPageInfo = queryData?.getProject?.legacyTasks?.pageInfo;
  const reportsPageInfo =
    queryData?.getProject?.crawls?.nodes[0]?.reports.pageInfo;
  const completed =
    segmentPageInfo?.hasNextPage === false &&
    tasksPageInfo?.hasNextPage === false &&
    !reportsPageInfo?.hasNextPage;

  if (completed) {
    dataInner.current = queryData;
  }

  const loading = Boolean(
    queryLoading || (!dataInner.current && !completed && !error),
  );

  const data = dataInner.current;
  const dataResult = React.useMemo(() => {
    const reports = data?.getProject?.crawls.nodes[0]?.reports.edges;

    return {
      moduleCode: data?.getProject?.moduleCode ?? ModuleCode.Basic,
      segments:
        data?.getProject?.segments?.edges.map((x) => {
          const crawlSegment = x.node.crawlSegments?.nodes[0];

          const failedAt = crawlSegment?.failedAt
            ? new Date(crawlSegment.failedAt)
            : undefined;
          const generatingAt = crawlSegment?.generatingAt
            ? new Date(crawlSegment.generatingAt)
            : undefined;
          const generatedAt = crawlSegment?.generatedAt
            ? new Date(crawlSegment.generatedAt)
            : undefined;

          const isFailed =
            failedAt && failedAt.getTime() > (generatingAt?.getTime() || 0);
          const isGenerating =
            crawlSegment &&
            !isFailed &&
            (!generatedAt ||
              generatedAt.getTime() < (generatingAt?.getTime() || 0));

          return {
            ...x.node,
            hasActiveTask: Boolean(
              data.getProject?.legacyTasks.edges.find(
                (y) => y.node.segment?.id === x.node.id,
              ),
            ),
            isSupportedFormat: validateDatasourceConnectionFilter(
              x.node.filter,
            ),
            isGenerating: Boolean(isGenerating),
            isFailed: Boolean(isFailed),
            report:
              reports?.find((report) => report.node.segmentId === x.node.id)
                ?.node || undefined,
          };
        }) || [],
      crawl: data?.getProject?.crawls.nodes[0],
      maxSegmentCount: data?.getProject?.account.maxSegmentsPerProject || 0,
    };
  }, [data]);

  useEffect(() => {
    if (
      segmentPageInfo?.hasNextPage !== true &&
      tasksPageInfo?.hasNextPage !== true &&
      reportsPageInfo?.hasNextPage !== true
    )
      return;

    fetchMore({
      variables: {
        projectId: getApiProjectId(projectId),
        cursor: segmentPageInfo?.endCursor,
        taskCursor: tasksPageInfo?.endCursor,
        reportCursor: reportsPageInfo?.endCursor,
      },
    });
  }, [segmentPageInfo, tasksPageInfo, reportsPageInfo, fetchMore, projectId]);

  return {
    data: dataResult ?? {
      moduleCode: ModuleCode.Basic,
      segments: [],
      crawl: undefined,
      activeSegments: [],
      maxSegmentCount: 0,
    },
    loading: loading,
    error: error,
  };
}
