import {
  BlueDataGrid,
  BlueGridRowSelector,
  Button,
  useSession,
  useTranslation,
  Alert,
} from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { useEffect, useMemo, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { ReportRowsFilter } from "../_common/connection-filtering/types";
import { Routes } from "../_common/routing/routes";
import { useURLSearchParams } from "../_common/routing/useURLSearchParams";
import { useDebounedFunction } from "../_common/useDebounedFunction";
import { useCrawlContextData } from "../crawl-overview/CrawlContext";
import { CrawlStatus, ReportTypeCode, RoleCode } from "../graphql";
import { getRowHeight } from "../report/report-rows/report-grid/_common/row-height-utils";
import { ColumnSelector } from "../report/report-rows/report-grid/columns/column-selector/ColumnSelector";
import { useReportGridColumns } from "../report/report-rows/report-grid/columns/useReportGridColumns";
import { DataGridStickyScroll } from "../report/report-rows/report-grid/DataGridStickyScroll";
import {
  ReportGridTabs,
  ReportGridTabsMode,
} from "../report/report-rows/report-grid/grid-tabs/GridTabs";
import { GridContext } from "../report/report-rows/report-grid/ReportGrid";
import { ReportGridAlerts } from "../report/report-rows/report-grid/rows/alerts/ReportGridAlerts";
import { ColumnSelectorButton } from "../report/report-rows/report-grid/rows/columns-selector-button/ColumnSelectorButton";
import { RowsFilters } from "../report/report-rows/report-grid/rows/rows-filters/RowsFilters";
import { useReportGridRows } from "../report/report-rows/report-grid/rows/useReportGridRows";
import { TableGridToggle } from "../report/report-rows/report-grid/TableGridToggle";
import { ReportEntity, ReportInput } from "../report/Report.types";
import {
  mapCustomReportMetricsGroupings,
  mapReportTypeCodeToString,
} from "./_common/CustomReportHelpers";
import {
  BaseReportTemplateDTO,
  CustomReportTemplateDTO,
} from "./_common/CustomReportTypes";
import { CustomReportHeader } from "./CustomReportHeader";
import { ExportRows } from "../report/report-rows/report-grid/rows/export-rows/ExportRows";

type CustomReportGridProps = {
  accountId: string;
  projectId: string;
  crawlId: string;
  reportTypeCode: ReportTypeCode;
  segmentId?: string;
  hideTaskMenu?: boolean;
  columnPersistanceKey?: string;
  lastFinishedCrawlId?: string;
  customReportTemplate: CustomReportTemplateDTO;
  baseReportTemplate: BaseReportTemplateDTO;
  reportAdviceCollapsed: boolean;
  tabsMode?: ReportGridTabsMode;
  baseFilter?: ReportRowsFilter;
};

const DEFAULT_PAGE_SIZE = 20;

export const CustomReportGrid = ({
  crawlId,
  accountId,
  projectId,
  reportTypeCode,
  columnPersistanceKey,
  segmentId,
  customReportTemplate,
  baseReportTemplate,
  baseFilter,
  reportAdviceCollapsed,
  tabsMode = "visible",
}: CustomReportGridProps): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const searchParams = useURLSearchParams();
  const { t } = useTranslation("report");
  const { t: tCustomReports } = useTranslation("customReports");
  const { crawlProject, projectAllCustomReportTemplates, crawl } =
    useCrawlContextData();
  const { hasSufficientRole } = useSession();

  const reportInput: ReportInput = useMemo(
    () => ({
      reportEntity: ReportEntity.CustomReport,
      customReportTemplateId: customReportTemplate.id,
      reportTemplateCode: baseReportTemplate.code,
      crawlId,
      segmentId,
      reportTypeCode,
    }),
    [
      baseReportTemplate.code,
      crawlId,
      customReportTemplate.id,
      segmentId,
      reportTypeCode,
    ],
  );

  const isCustomReportTemplateDeleted = !projectAllCustomReportTemplates.find(
    (c) => c.id.split("_")?.[1] === customReportTemplate.rawID,
  );

  const metricsGroupingsOverwrite = useMemo(
    () =>
      mapCustomReportMetricsGroupings(
        customReportTemplate.metricsGroupings ?? [],
      ),
    [customReportTemplate.metricsGroupings],
  );

  const {
    loading: columnsLoading,
    error: columnError,
    definitions,
    cardMetrics,
    defaultMetrics,
    foundInSources,
    storedStates,
    isCrawlIncomplete,
    compareToCrawlId,
    metricsData,
    filterMetrics,
    datasourceCode,
    crawlTypesMetadata,
    saveColumnsState,
    isGridView,
    toggleGridView,
  } = useReportGridColumns({
    reportInput,
    columnPersistanceKey,
    overwrites: {
      metricsGroupings: metricsGroupingsOverwrite,
    },
  });

  const {
    rows,
    totalCount,
    totalRows,
    pagination,
    loading: dataLoading,
    error: dataError,
    diffs,
    customFilter,
  } = useReportGridRows({
    pageSize: DEFAULT_PAGE_SIZE,
    metricsData,
    datasourceCode,
    reportInput,
    baseFilter,
    fetchFilteredMissing:
      crawlProject.crawls.totalCount > 1 &&
      crawl.comparedTo?.statusEnum === CrawlStatus.Finished,
  });

  const apiRef = useGridApiRef();
  const loading = dataLoading || columnsLoading;
  const error = dataError || columnError;

  const [visibleColumns, setVisibleColumns] = useState<string[]>(
    storedStates?.filter((x) => !x.hide).map((x) => x.code) ?? [],
  );

  useEffect(() => {
    setVisibleColumns(
      storedStates?.filter((x) => !x.hide).map((x) => x.code) ?? [],
    );
  }, [storedStates]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [reportInput]);

  const { debounce } = useDebounedFunction(300);

  useEffect(() => {
    searchParams.set("reportTemplateCode", baseReportTemplate.code);
    searchParams.set("reportType", mapReportTypeCodeToString(reportTypeCode));
    searchParams.apply();
  }, [baseReportTemplate.code, reportTypeCode, searchParams]);

  if (isCrawlIncomplete) {
    return (
      <Redirect
        exact
        to={Routes.Crawls.getUrl({
          accountId,
          projectId,
          tab: "progress",
        })}
      />
    );
  }

  const rowHeight = (() => {
    if (isGridView) return 42;
    return getRowHeight({
      rows,
      visibleColumns,
      definitions,
      cardMetrics,
      hasFounInMetric: Boolean(foundInSources.length),
      metricsData,
      isGridView,
    });
  })();

  const errorMessage =
    error?.graphQLErrors[0]?.message ??
    error?.networkError?.message ??
    error?.clientErrors[0]?.message ??
    error?.protocolErrors[0]?.message;

  const projectCustomReportTemplate = projectAllCustomReportTemplates.find(
    (c) => c.id.split("_")?.[1] === customReportTemplate.rawID,
  );

  const isPendingImpactOrWeightUpdate =
    (projectCustomReportTemplate?.reportTemplateTotalSign !==
      customReportTemplate.totalSign ||
      projectCustomReportTemplate.reportTemplateTotalWeight !==
        customReportTemplate.totalWeight) &&
    !isCustomReportTemplateDeleted;

  return (
    <GridContext.Provider
      value={{
        datasourceCode,
        crawlTypesMetadata,
        apiRef,
        compareToCrawlId,
        totalCount: totalCount ?? 0,
        loading: loading,
        isGridView,
        toggleGridView,
        reportInput,
        filter: customFilter,
        filterMetrics,
        cardMetrics,
        foundInSources,
      }}
    >
      <div>
        <CustomReportHeader
          title={customReportTemplate.name}
          accountId={accountId}
          projectId={projectId}
          crawlId={crawlId}
          customReportTemplate={customReportTemplate}
          baseReportTemplate={baseReportTemplate}
          reportAdviceCollapsed={reportAdviceCollapsed}
          isCustomReportTemplateDeleted={isCustomReportTemplateDeleted}
          reportInput={reportInput}
        />
        <ReportGridTabs
          mode={tabsMode}
          diffs={diffs}
          totalRows={totalRows}
          loading={loading}
          reportInput={reportInput}
          onTabChange={(value) => {
            searchParams.set("reportType", value);
            searchParams.apply();
          }}
        >
          {hasSufficientRole(RoleCode.Editor) ? (
            <Button
              onClick={(event) => {
                event.preventDefault();
                history.push(
                  Routes.CustomReportTemplateManager.getUrl({
                    accountId,
                    projectId,
                    crawlId,
                  }),
                );
              }}
              variant="outlined"
              size="large"
              data-pendo="manage-custom-reports"
            >
              {tCustomReports("manageCustomReports")}
            </Button>
          ) : null}
        </ReportGridTabs>
        {isPendingImpactOrWeightUpdate ? (
          <Alert severity="warning" style={{ marginBottom: 10 }}>
            {tCustomReports("pendingImpactOrWeightUpdate")}
          </Alert>
        ) : null}
        <ReportGridAlerts error={error}>
          <DataGridStickyScroll data-testid="urls-table">
            <BlueDataGrid
              sticky
              rowHeight={rowHeight}
              disableColumnSelector={false}
              defaultPaginationState={{ pageSize: DEFAULT_PAGE_SIZE }}
              onColumnResize={(p, e, d) =>
                debounce(() => saveColumnsState(d.api.state))
              }
              onColumnOrderChange={(p, e, d) => {
                debounce(() => saveColumnsState(d.api.state));
              }}
              onColumnVisibilityChange={(p, e, d) => {
                debounce(() => saveColumnsState(d.api.state));
                if (p.isVisible) {
                  setVisibleColumns((visibleColumns) => [
                    p.field,
                    ...visibleColumns,
                  ]);
                } else if (!p.isVisible) {
                  setVisibleColumns((visibleColumns) =>
                    visibleColumns.filter((x) => x !== p?.field),
                  );
                }
              }}
              apiRef={apiRef}
              disableColumnsButton
              showCellRightBorder
              showColumnRightBorder
              rows={rows ? rows : []}
              loading={loading}
              rowCount={totalCount}
              totalRowCount={totalRows}
              error={error}
              columns={definitions}
              classes={{ root: classes.root }}
              pagination
              components={{
                ToolbarLeft: [
                  RowsFilters,
                  ColumnSelectorButton,
                  TableGridToggle,
                ],
                ToolbarRight: [BlueGridRowSelector, ExportRows],
                ColumnsPanel: ColumnSelector,
              }}
              componentsProps={{
                columnMenu: { style: { paddingLeft: 8, paddingRight: 8 } },
                toolbar: {
                  disabled: Boolean(error),
                },
                columnsPanel: {
                  defaultColumns: [
                    "card",
                    ...defaultMetrics.map((x) => x.code),
                  ],
                  insertIndex: isGridView ? 0 : 1,
                },
                pagination: {
                  hideLastPages: true,
                },
                noRowsOverlay: {
                  title: t("noRowsTitle"),
                  detail: t("noRowsDetail"),
                },
                noResultsOverlay: {
                  title: t("noResultsTitle"),
                  detail: t("noResultsDetail"),
                },
                errorOverlay: {
                  hideReloadButton: !Boolean(customFilter),
                  detail: errorMessage,
                  onReload: () => {
                    // One of the possible cause of the error state is a malformed/invalid filter or
                    // pagination data. In this case a simple reload would not solve the issue, and
                    // because the overlay hides the grid's controls the user is not able to change or
                    // clear these values. - Csaba

                    /**
                     * Added a full page reload by replacing the current URL with the same URL but without
                     * the filter and pagination query parameters, as there are cases where the error might
                     * be caused by another component like GraphQLClient and calling history.push() would
                     * not reset those components causing the issue to persist and forcing the user to manually
                     * reload the full page.
                     * @author Alex Sánchez
                     */
                    const currentUrl = new URL(window.location.href);
                    currentUrl.searchParams.delete("filter");
                    currentUrl.searchParams.delete("pagination");

                    window.location.replace(currentUrl.href);
                  },
                },
              }}
              paginationMode="server"
              {...pagination}
              rowsPerPageOptions={[10, 20, 50, 100]}
              sortingOrder={["desc", "asc", null]}
            />
          </DataGridStickyScroll>
        </ReportGridAlerts>
      </div>
    </GridContext.Provider>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 12,
    color: theme.palette.grey[800],
    "& .MuiDataGrid-toolbarContainer": {
      maxHeight: 64,
    },
  },
}));
