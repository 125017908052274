import React from "react";

import { useParams } from "react-router-dom";
import { FormikProps } from "formik";
import { SegmentRulesVisualizer } from "../SegmentRulesVisualizer";

import {
  ArrowLeftSolid,
  Typography,
  useTranslation,
  getApiProjectId,
} from "@lumar/shared";
import { SegmentDialog, SegmentDialogProps } from "./SegmentDialog";
import { SegmentFormValues } from "./SegmentForm";
import { NarrowMetric } from "../../_common/connection-filtering/types";
import { getFilterOrRuleArrayValidationSchema } from "../../_common/connection-filtering/filter-or-rule-field-array/getFilterOrRuleValidationSchema";
import { ReportTypeCode, usePreviewSegmentQuery } from "../../graphql";
import { PreviewSegmentContent } from "./PreviewSegmentContent";
import { mapOrFormFiltersToDatasourceConnectionFilter } from "../../_common/connection-filtering/filter-or-rule-field-array/mapOrFormFiltersToDatasourceConnectionFilter";
import { IconButton, makeStyles } from "@material-ui/core";
import { SegmentDialogFooter } from "./SegmentDialogFooter";

const useStyles = makeStyles((theme) => ({
  rulesContainer: {
    wordBreak: "break-all",
    maxHeight: 100,
    overflowY: "auto",
    color: theme.palette.grey[700],
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.75),
  },
  goBackButton: {
    marginRight: theme.spacing(2),
  },
  titleDiv: {
    display: "flex",
  },
  arrow: {
    marginRight: theme.spacing(2),
  },
}));

interface SegmentDialogWithPreview extends Omit<SegmentDialogProps, "footer"> {
  values: SegmentFormValues;
  metrics: NarrowMetric[];
  isSubmitting: boolean;
  lastFinishedCrawlId: string;
  isPreviewDisabled: boolean;
  submitBtnText: string;
  onSubmit: FormikProps<SegmentFormValues>["submitForm"];
  setTouched: FormikProps<SegmentFormValues>["setTouched"];
  validateForm: FormikProps<SegmentFormValues>["validateForm"];
  previewMessage: (projectName: string) => string;
}

export function SegmentDialogWithPreview(
  props: SegmentDialogWithPreview,
): JSX.Element {
  const classes = useStyles();
  const [showPreview, setShowPreview] = React.useState(false);
  const { projectId } = useParams<{ projectId: string }>();
  const { t: tConnectionFiltering } = useTranslation("connectionFiltering");
  const {
    metrics,
    values,
    lastFinishedCrawlId,
    isPreviewDisabled,
    submitBtnText,
    setTouched,
    validateForm,
    previewMessage,
    onSubmit,
  } = props;

  const validationSchema = getFilterOrRuleArrayValidationSchema(
    metrics,
    tConnectionFiltering,
  );

  const {
    data,
    loading: previewLoading,
    error,
  } = usePreviewSegmentQuery({
    variables: {
      projectId: getApiProjectId(projectId),
      // TODO: While we currently only support the last finished crawl - we are
      // using the lastFinishedCrawlId prop here directly. In the future, create
      // new state, use the state, here, set initial state as the prop and and
      // have the crawl dropdown update the state on change - Saul.
      crawlId: lastFinishedCrawlId,
      reportTemplateCode: "all_pages",
      reportTypeCode: ReportTypeCode.Basic.toLowerCase(),
      // FIXME: Formik doesn't respect `transform` and `trim` in yup schema.
      // Formik devs will fix it in this pr: https://github.com/jaredpalmer/formik/pull/2255
      // This is why `schema.cast` has to be used.
      filter: mapOrFormFiltersToDatasourceConnectionFilter(
        (showPreview &&
          validationSchema.cast(props.values.segments[0].rules)) ||
          [],
      ),
    },
    fetchPolicy: "cache-first",
    skip: !showPreview,
  });

  const projectName = data?.project?.name;

  if (showPreview) {
    return (
      <SegmentDialog
        {...props}
        closeDialog={() => setShowPreview(false)}
        dialogTitle={
          <div className={classes.titleDiv}>
            <IconButton
              className={classes.arrow}
              onClick={() => setShowPreview(false)}
            >
              <ArrowLeftSolid />
            </IconButton>
            <div>
              <Typography
                component="h2"
                variant="h2"
                id="preview-segment-dialog-title"
                data-testid="preview-segment-dialog-title"
              >
                {values.segments[0].segmentName} (
                {data?.crawl?.reports.nodes[0].rows?.nodes?.length ?? 0})
              </Typography>
              <Typography
                variant="subtitle3Medium"
                className={classes.rulesContainer}
              >
                <SegmentRulesVisualizer
                  metrics={metrics}
                  rules={values.segments[0].rules}
                />
              </Typography>
            </div>
          </div>
        }
        footer={() => (
          <SegmentDialogFooter
            onCancel={() => null}
            onSubmit={onSubmit}
            onEdit={() => setShowPreview(false)}
            submitDisabled={previewLoading || Boolean(error)}
            submitBtnText={submitBtnText}
          >
            <Typography variant="subtitle3Medium">
              {projectName && previewMessage(projectName)}
            </Typography>
          </SegmentDialogFooter>
        )}
      >
        <PreviewSegmentContent
          data={data}
          previewLoading={previewLoading}
          error={error}
        />
      </SegmentDialog>
    );
  }

  async function onPreviewSegment(): Promise<void> {
    const errors = await validateForm();
    if (Object.keys(errors).length) {
      // NOTE: Validating the form using validateForm() will validate form values
      // but will not set the touched value of each field that has been validated to
      // true (in the way it would after validation on submit). We have to manually
      // set each field as 'touched' to make sure that the user sees the error
      // message, otherwise validate will return errors but they won't be displayed
      // under the fields (because form components only show an error if they have
      // both been touched and contain an error to avoid showing errors on an input
      // before a user has interacted with it). - Saul.
      setTouched({
        projectDestination: true,
        segments: [
          {
            segmentName: true,
            rules: values.segments[0].rules.map((r) => {
              return {
                _and: r._and.map(() => ({
                  metricCode: true,
                  predicateKey: true,
                  predicateValue: true,
                })),
              };
            }),
          },
        ],
      });
    } else {
      setShowPreview(true);
    }
  }

  return (
    <SegmentDialog
      {...props}
      footer={
        <SegmentDialogFooter
          onSubmit={props.onSubmit}
          submitBtnText={submitBtnText}
          onPreview={isPreviewDisabled ? undefined : onPreviewSegment}
          onCancel={props.closeDialog}
        />
      }
    />
  );
}
