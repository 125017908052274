import { useState } from "react";
import {
  XCircleSolid,
  SearchSolid,
  TextField,
  useTranslation,
} from "@lumar/shared";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import { animated, useSpring } from "react-spring";
import { easeBackOut } from "d3-ease";

import { Routes } from "../_common/routing/routes";
import { ReportTypeCode } from "../graphql";

const useStyles = makeStyles((theme) => ({
  rootSmall: {
    "&:hover $icon": {
      color: theme.palette.primary.main,
    },
    "&:hover $input": {
      borderColor: theme.palette.secondary.main,
      background: theme.palette.secondary.main,
    },
    "&:hover $input input": {
      cursor: "pointer",
    },
  },
  input: {
    padding: 0,
    overflow: "hidden",
    "& input": {
      zIndex: 1,
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(1.25),
    },
  },
  icon: {
    fontSize: theme.typography.pxToRem(22),
    color: theme.palette.grey[600],
    position: "absolute",
    right: 6,
  },
  clearIcon: {
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.grey[500],
    position: "absolute",
    right: 6,
    zIndex: 2,
    cursor: "pointer",
  },
}));

const REPORT_SEARH_CLOSED_WIDTH = 36;
const REPORT_SEARH_OPEN_WIDTH = 167;

export function ReportSearch(): JSX.Element {
  const { t } = useTranslation("reportSearch");
  const classes = useStyles();

  const [value, setValue] = useState("");
  const [focused, setFocused] = useState<boolean | undefined>(undefined);
  const [hover, setHover] = useState(false);

  const open = Boolean(value) || focused;
  const showTooltip = hover && !open;

  const springProps = useSpring(
    open === undefined
      ? {
          to: {
            width: REPORT_SEARH_CLOSED_WIDTH,
            minWidth: REPORT_SEARH_CLOSED_WIDTH,
          },
        }
      : open
        ? {
            from: {
              width: REPORT_SEARH_CLOSED_WIDTH,
              minWidth: REPORT_SEARH_CLOSED_WIDTH,
            },
            to: {
              width: REPORT_SEARH_OPEN_WIDTH,
              minWidth: REPORT_SEARH_OPEN_WIDTH,
            },
            config: { duration: 300, easing: easeBackOut },
          }
        : {
            from: {
              width: REPORT_SEARH_OPEN_WIDTH,
              minWidth: REPORT_SEARH_OPEN_WIDTH,
            },
            to: {
              width: REPORT_SEARH_CLOSED_WIDTH,
              minWidth: REPORT_SEARH_CLOSED_WIDTH,
            },
            config: { duration: 300, easing: easeBackOut },
          },
  );

  const history = useHistory();
  const { accountId, projectId, crawlId } = useParams<{
    accountId: string;
    projectId: string;
    crawlId: string;
  }>();

  function handleSearch(): void {
    history.push(
      Routes.Report.getUrl({
        accountId,
        projectId,
        crawlId,
        reportTemplateCode: "all_pages",
        reportTypeCode: ReportTypeCode.Basic.toLowerCase(),
        filter: {
          _or: [
            {
              _and: [
                {
                  url: {
                    contains: value,
                  },
                },
              ],
            },
          ],
        },
      }),
    );
  }

  return (
    <Tooltip open={showTooltip} title={t("tooltip")}>
      <animated.div style={springProps}>
        <TextField
          value={value}
          onChange={(e) => setValue(e.target.value)}
          InputProps={{
            onKeyPress: (e) => {
              if (value && e.key === "Enter") handleSearch();
              if (value && e.key === "Escape") setValue("");
            },
            endAdornment: value ? (
              <XCircleSolid
                className={classes.clearIcon}
                onClick={() => setValue("")}
              />
            ) : (
              <SearchSolid className={classes.icon} />
            ),
            classes: {
              root: classes.input,
            },
          }}
          inputProps={{
            "aria-label": t("tooltip"),
          }}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          className={clsx({ [classes.rootSmall]: !open })}
          data-testid="search-url"
        />
      </animated.div>
    </Tooltip>
  );
}
