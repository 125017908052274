import React from "react";
import {
  CrawlContextCrawlReportUnion,
  CrawlContextValue,
  createCrawlReportCategoriesList,
  createCrawlReportCategoriesTree,
  getCrawlReportCategoryTreeNode,
  getCrawlReportCategoryTreeNodeReportsList,
  getCrawlReportsMatchingSearchTerm,
} from "./CrawlContext";
import {
  CrawlContextAllReportCategoriesQuery,
  CrawlContextDataQuery,
  CrawlContextIndustryBenchmarksQuery,
  useCrawlContextSegmentsQuery,
} from "../graphql";
import { useURLSearchParams } from "../_common/routing/useURLSearchParams";

interface Props {
  data: CrawlContextValue["data"];
  crawlContextData: CrawlContextDataQuery | undefined;
  allReportCategoriesData: CrawlContextAllReportCategoriesQuery | undefined;
  industryBenchmarksData: CrawlContextIndustryBenchmarksQuery | undefined;
  fetchMoreSegments: ReturnType<
    typeof useCrawlContextSegmentsQuery
  >["fetchMore"];
}

export function useCrawlContextHelpersFunctions({
  data,
  crawlContextData,
  allReportCategoriesData,
  industryBenchmarksData,
  fetchMoreSegments,
}: Props): CrawlContextValue["helpers"] {
  const reportHelpers = React.useMemo<
    | undefined
    | Pick<
        NonNullable<CrawlContextValue["helpers"]>,
        | "getCrawlReportCategoryTreeNode"
        | "getCrawlReportCategoryReportsList"
        | "getCrawlCustomReportsList"
        | "getErrorCrawlReportCategoryTreeNode"
        | "getChangedCrawlReportCategoryTreeNode"
        | "getCrawlReportCategoriesListWithReportsMatchingSearchTerm"
        | "getCrawlReportCategoryErrorReportsList"
        | "getCrawlErrorCustomReportsList"
        | "getCrawlReportCategoryChangedReportsList"
        | "getProjectAllCustomReportTemplatesList"
      >
  >(() => {
    if (!data || !allReportCategoriesData) return;

    /* eslint-disable fp/no-mutating-methods*/
    const crawlErrorReports = data.crawlReports
      .filter(
        (report) =>
          (report.basic ?? 0) > 0 && (report.reportTemplateTotalSign ?? 0) < 0,
      )
      .sort(errorReportUnionImpactComparator);

    const crawlErrorCustomReports = data.crawlCustomReports
      .filter(
        (report) =>
          (report.basic ?? 0) > 0 && (report.reportTemplateTotalSign ?? 0) < 0,
      )
      .sort(errorReportUnionImpactComparator);

    const crawlChangedReports = data.crawlReports
      .filter((report) => !!report.change)
      .sort(changedReportUnionWeightComparator);

    const crawlReportCategoriesTree = createCrawlReportCategoriesTree(
      allReportCategoriesData,
      data.crawlReports,
    );

    const crawlErrorReportCategoriesTree = createCrawlReportCategoriesTree(
      allReportCategoriesData,
      crawlErrorReports,
    );

    const crawlChangedReportCategoriesTree = createCrawlReportCategoriesTree(
      allReportCategoriesData,
      crawlChangedReports,
    );

    return {
      getCrawlReportCategoryTreeNode(
        crawlReportCategoryCode: string,
        searchTerm?: string,
      ) {
        if (searchTerm) {
          const foundCrawlReports = getCrawlReportsMatchingSearchTerm(
            data.crawlReports,
            searchTerm,
          );

          const tree = createCrawlReportCategoriesTree(
            allReportCategoriesData,
            foundCrawlReports,
          );

          const node = getCrawlReportCategoryTreeNode(
            crawlReportCategoryCode,
            tree,
          );

          return node;
        }

        return getCrawlReportCategoryTreeNode(
          crawlReportCategoryCode,
          data.crawlReportCategoriesTree,
        );
      },
      getCrawlCustomReportsList(searchTerm?: string) {
        if (searchTerm) {
          const foundCrawlReports = getCrawlReportsMatchingSearchTerm(
            data.crawlCustomReports,
            searchTerm,
          );

          return foundCrawlReports;
        }

        return data.crawlCustomReports;
      },
      getProjectAllCustomReportTemplatesList(searchTerm?: string) {
        if (searchTerm) {
          const foundCrawlReports = getCrawlReportsMatchingSearchTerm(
            data.projectAllCustomReportTemplates,
            searchTerm,
          );

          return foundCrawlReports;
        }

        return data.projectAllCustomReportTemplates;
      },
      getErrorCrawlReportCategoryTreeNode(
        crawlReportCategoryCode: string,
        searchTerm?: string,
      ) {
        if (searchTerm) {
          const foundCrawlReports = getCrawlReportsMatchingSearchTerm(
            crawlErrorReports,
            searchTerm,
          );

          const tree = createCrawlReportCategoriesTree(
            allReportCategoriesData,
            foundCrawlReports,
          );

          const node = getCrawlReportCategoryTreeNode(
            crawlReportCategoryCode,
            tree,
          );

          return node;
        }

        return getCrawlReportCategoryTreeNode(
          crawlReportCategoryCode,
          crawlErrorReportCategoriesTree,
        );
      },

      getChangedCrawlReportCategoryTreeNode(
        crawlReportCategoryCode: string,
        searchTerm?: string,
      ) {
        if (searchTerm) {
          const foundCrawlReports = getCrawlReportsMatchingSearchTerm(
            crawlChangedReports,
            searchTerm,
          );

          const tree = createCrawlReportCategoriesTree(
            allReportCategoriesData,
            foundCrawlReports,
          );

          const node = getCrawlReportCategoryTreeNode(
            crawlReportCategoryCode,
            tree,
          );

          return node;
        }

        return getCrawlReportCategoryTreeNode(
          crawlReportCategoryCode,
          crawlChangedReportCategoriesTree,
        );
      },

      getCrawlReportCategoriesListWithReportsMatchingSearchTerm(
        searchTerm: string,
      ) {
        const foundCrawlReports = getCrawlReportsMatchingSearchTerm(
          data.crawlReports,
          searchTerm,
        );

        const tree = createCrawlReportCategoriesTree(
          allReportCategoriesData,
          foundCrawlReports,
        );

        const list = createCrawlReportCategoriesList(tree);

        return list;
      },

      getCrawlReportCategoryReportsList(
        crawlReportCategoryCode: string,
        searchTerm?: string,
      ) {
        if (searchTerm) {
          const foundCrawlReports = getCrawlReportsMatchingSearchTerm(
            data.crawlReports,
            searchTerm,
          );

          const tree = createCrawlReportCategoriesTree(
            allReportCategoriesData,
            foundCrawlReports,
          );

          const node = getCrawlReportCategoryTreeNode(
            crawlReportCategoryCode,
            tree,
          );

          if (node) {
            return getCrawlReportCategoryTreeNodeReportsList(node);
          }

          return [];
        }

        const node = getCrawlReportCategoryTreeNode(
          crawlReportCategoryCode,
          crawlReportCategoriesTree,
        );

        if (node) {
          return getCrawlReportCategoryTreeNodeReportsList(node);
        }

        return [];
      },

      getCrawlReportCategoryErrorReportsList(
        crawlReportCategoryCode: string,
        searchTerm?: string,
      ) {
        if (searchTerm) {
          const foundCrawlReports = getCrawlReportsMatchingSearchTerm(
            crawlErrorReports,
            searchTerm,
          );

          const tree = createCrawlReportCategoriesTree(
            allReportCategoriesData,
            foundCrawlReports,
          );

          const node = getCrawlReportCategoryTreeNode(
            crawlReportCategoryCode,
            tree,
          );

          if (node) {
            // eslint-disable-next-line fp/no-mutating-methods
            return getCrawlReportCategoryTreeNodeReportsList(node).sort(
              errorReportUnionImpactComparator,
            );
          }

          return [];
        }

        const node = getCrawlReportCategoryTreeNode(
          crawlReportCategoryCode,
          crawlErrorReportCategoriesTree,
        );

        if (node) {
          // eslint-disable-next-line fp/no-mutating-methods
          return getCrawlReportCategoryTreeNodeReportsList(node).sort(
            errorReportUnionImpactComparator,
          );
        }

        return [];
      },
      getCrawlErrorCustomReportsList(searchTerm?: string) {
        if (searchTerm) {
          const foundCrawlReports = getCrawlReportsMatchingSearchTerm(
            crawlErrorCustomReports,
            searchTerm,
          );

          return foundCrawlReports.sort(errorReportUnionImpactComparator);
        }

        return crawlErrorCustomReports.sort(errorReportUnionImpactComparator);
      },
      getCrawlReportCategoryChangedReportsList(
        crawlReportCategoryCode: string,
        searchTerm?: string,
      ) {
        if (searchTerm) {
          const foundCrawlReports = getCrawlReportsMatchingSearchTerm(
            crawlChangedReports,
            searchTerm,
          );

          const tree = createCrawlReportCategoriesTree(
            allReportCategoriesData,
            foundCrawlReports,
          );

          const node = getCrawlReportCategoryTreeNode(
            crawlReportCategoryCode,
            tree,
          );

          if (node) {
            // eslint-disable-next-line fp/no-mutating-methods
            return getCrawlReportCategoryTreeNodeReportsList(node).sort(
              changedReportUnionWeightComparator,
            );
          }

          return [];
        }
        const node = getCrawlReportCategoryTreeNode(
          crawlReportCategoryCode,
          crawlChangedReportCategoriesTree,
        );

        if (node) {
          // eslint-disable-next-line fp/no-mutating-methods
          return getCrawlReportCategoryTreeNodeReportsList(node).sort(
            changedReportUnionWeightComparator,
          );
        }

        return [];
      },
    };
  }, [allReportCategoriesData, data]);

  const searchParams = useURLSearchParams();
  const selectCrawlSegment = React.useCallback(
    (id: string | null): void => {
      if (id) {
        searchParams.set("segmentId", id);
      } else {
        searchParams.delete("segmentId");
      }
      searchParams.apply();
    },
    [searchParams],
  );

  const fetchMoreSegmentsHandler = React.useCallback(async () => {
    const pageInfo = crawlContextData?.getCrawl?.crawlSegments?.pageInfo;
    if (!pageInfo?.endCursor || !pageInfo.hasNextPage) return;

    await fetchMoreSegments({
      variables: {
        cursor: pageInfo.endCursor,
      },
    });
  }, [crawlContextData, fetchMoreSegments]);

  const getIndustryHealthScoreCategoryBenchmark = React.useCallback(
    (categoryCode, industryCode) => {
      if (!categoryCode || !industryCode) {
        return undefined;
      }

      return industryBenchmarksData?.getIndustryBenchmarks.edges.find(
        (benchmark) =>
          benchmark.node.reportCategoryCode === categoryCode &&
          benchmark.node.industryCode === industryCode,
      )?.node.healthScore;
    },
    [industryBenchmarksData?.getIndustryBenchmarks.edges],
  );

  return React.useMemo<CrawlContextValue["helpers"]>(() => {
    if (!reportHelpers) return undefined;

    return {
      ...reportHelpers,
      selectCrawlSegment,
      fetchMoreSegments: fetchMoreSegmentsHandler,
      getIndustryHealthScoreCategoryBenchmark,
    };
  }, [
    reportHelpers,
    selectCrawlSegment,
    fetchMoreSegmentsHandler,
    getIndustryHealthScoreCategoryBenchmark,
  ]);
}

/**
 * Sorts error crawl reports by impact in descending order.
 */
function errorReportUnionImpactComparator(
  a: CrawlContextCrawlReportUnion,
  b: CrawlContextCrawlReportUnion,
): number {
  const diff =
    (b.basic ?? 0) * (b.reportTemplateTotalWeight ?? 0) -
    (a.basic ?? 0) * (a.reportTemplateTotalWeight ?? 0);
  return diff;
}

/**
 * Sorts changed reports by change weight in descending order.
 */
function changedReportUnionWeightComparator(
  a: CrawlContextCrawlReportUnion,
  b: CrawlContextCrawlReportUnion,
): number {
  const diff =
    Math.abs(b.change ?? 0) * (b.reportTemplateTotalWeight ?? 0) -
    Math.abs(a.change ?? 0) * (a.reportTemplateTotalWeight ?? 0);
  if (diff === 0) {
    return b.reportTemplate.code.localeCompare(a.reportTemplate.code);
  }
  return diff;
}
