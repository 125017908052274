import { useTranslation } from "@lumar/shared";
import { Metrics, ResourceDetailData } from "../../../data/types";
import {
  MetricsGroups,
  MetricsGroupsConfig,
} from "../../../metrics-groups/MetricsGroups";
import { insertIf } from "../../../../_common/insertIf";
import { CrawlType } from "../../../../graphql";

export function AccessibilitySummary({
  data,
}: {
  data: ResourceDetailData;
}): JSX.Element {
  const config = useMetricsConfig(data.metrics, data.crawl);

  return <MetricsGroups metrics={data.metrics} config={config} />;
}

function useMetricsConfig(
  metrics: Metrics,
  crawl: ResourceDetailData["crawl"],
): MetricsGroupsConfig {
  const { t } = useTranslation("resourceDetail");

  const issuesCountByRuleId = metrics["accessibilityIssuesCountByRuleId"];
  const showIssuesCountByRuleId = Array.isArray(issuesCountByRuleId?.value)
    ? issuesCountByRuleId.value.length > 0
    : false;

  const isLogSummaryEnabled = crawl.crawlTypes.includes(CrawlType.LogSummary);
  const isAnalyticsEnabled = crawl.crawlTypes.includes(
    CrawlType.GoogleAnalytics,
  );
  const isSearchConsoleEnabled = crawl.crawlTypes.includes(
    CrawlType.GoogleSearchConsole,
  );
  const isBacklinksEnabled = crawl.crawlTypes.includes(CrawlType.Backlinks);

  return [
    {
      name: t("accessibilityMetrics"),
      items: [
        { code: "accessibilityAIssuesCount" },
        { code: "accessibilityAaIssuesCount" },
        { code: "accessibilityAaaIssuesCount" },
        { code: "accessibilityBpIssuesCount" },
        { code: "accessibilityCriticalIssuesCount" },
      ],
    },
    ...insertIf(showIssuesCountByRuleId, {
      name: issuesCountByRuleId.name,
      // eslint-disable-next-line fp/no-mutating-methods
      items: (
        [...issuesCountByRuleId.value] as {
          rule_id: string;
          issues_count: number;
        }[]
      )
        .sort((a, b) => {
          const diffByIssueCount = b.issues_count - a.issues_count;
          if (diffByIssueCount !== 0) return diffByIssueCount;
          return a.rule_id.localeCompare(b.rule_id);
        })
        .map((x) => ({
          code: x.rule_id,
          value: x.issues_count,
        })),
    }),
    ...insertIf(isLogSummaryEnabled, {
      name: t("groupLogFiles"),
      items: [
        { code: "logRequestsTotal" },
        { code: "logRequestsDesktop" },
        { code: "logRequestsMobile" },
      ],
    }),
    ...insertIf(isAnalyticsEnabled, {
      name: t("groupAnalytics"),
      items: [
        { code: "gaVisits" },
        { code: "gaVisitBounceRate" },
        { code: "gaAvgPageLoadTime" },
        { code: "gaAvgTimeOnPage" },
        { code: "gaPageviewsPerVisits" },
      ],
    }),
    ...insertIf(isSearchConsoleEnabled, {
      name: "Search Console",
      items: [
        { code: "searchConsoleTotalImpressions" },
        { code: "searchConsoleTotalClicks" },
        { code: "searchConsoleTotalCtr" },
        { code: "searchConsoleTotalPosition" },
        { code: "searchConsoleDesktopImpressions" },
        { code: "searchConsoleDesktopClicks" },
        { code: "searchConsoleDesktopCtr" },
        { code: "searchConsoleDesktopPosition" },
        { code: "searchConsoleMobileImpressions" },
        { code: "searchConsoleMobileClicks" },
        { code: "searchConsoleMobileCtr" },
        { code: "searchConsoleMobilePosition" },
        { code: "searchConsoleTabletImpressions" },
        { code: "searchConsoleTabletClicks" },
        { code: "searchConsoleTabletCtr" },
        { code: "searchConsoleTabletPosition" },
      ],
    }),
    ...insertIf(isBacklinksEnabled, {
      name: t("groupBacklinks"),
      items: [{ code: "backlinkCount" }, { code: "backlinkDomainCount" }],
    }),
  ];
}
