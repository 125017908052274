import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import {
  CreateTaskFormValuesResult,
  useEditTaskFormValues,
} from "../data/useEditTaskFormValues";
import {
  ArchiveSolid,
  Button,
  DestructiveMenuActionConfirmation,
  EmptyState,
  PencilSolid,
  SmileySad,
  SparklesSolid,
  TrashSolid,
  useSession,
  useTranslation,
  Close as CloseIcon,
  getRawCrawlId,
  getRawProjectId,
  useBrand,
} from "@lumar/shared";
import { Formik } from "formik";
import { EditTaskForm } from "./EditTaskForm";
import { CustomSkeleton } from "../../_common/CustomSkeleton";
import { useTaskSchema } from "./useTaskSchema";
import { GenerateDeliveryTicketDialog } from "../generate-delivery-ticket/GenerateDeliveryTicketDialog";
import { RoleCode } from "@lumar/shared/dist/graphql";
import { TitleField } from "./TitleField";
import { Routes } from "../../_common/routing/routes";
import { useParams } from "react-router-dom";
import { isNotEmptyConnectionFilter } from "../../_common/connection-filtering/isNotEmptyConnectionFilter";
import clsx from "clsx";
import { TaskTrendChart } from "./TaskTrendChart";
import { LegacyTaskStatus } from "../../graphql";

export interface EditTaskDialogProps {
  taskId: string;
  onClose: () => void;
  accountSearch?: string;
  onStatusUpdated?: (status: LegacyTaskStatus) => void;
}

export function EditTaskDialog({
  taskId,
  onClose,
  accountSearch,
  onStatusUpdated,
}: EditTaskDialogProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("taskManager");
  const { account, hasSufficientRole } = useSession();
  const { accountId } = useParams<{
    accountId: string;
  }>();
  const { featureAvailability } = useBrand();

  const [open, setOpen] = React.useState(true);
  const [isEditing, setIsEditing] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isArchiving, setIsArchiving] = React.useState(false);
  const [showDeliveryTicket, setShowDeliveryTicket] = React.useState(false);

  const deleteButtonRef = React.useRef<HTMLButtonElement | null>(null);
  const archiveButtonRef = React.useRef<HTMLButtonElement | null>(null);

  const {
    loading,
    error,
    values,
    task,
    taggedTrendLoading,
    taggedTrends,
    updateTask,
    deleteTask,
    archiveTask,
  } = useEditTaskFormValues({
    taskId,
    accountSearch,
    onStatusUpdated,
  });

  const schema = useTaskSchema();

  const disabled = loading || Boolean(error);
  const canEdit = hasSufficientRole(RoleCode.Editor);
  const isArchived = Boolean(task?.fixedAt);

  const link = getTaskLink(task, accountId);

  const showGenerateDeliveryTicket =
    featureAvailability.generateDeliveryTicket && account.aiFeaturesEnabled;

  return (
    <>
      <Formik
        initialValues={values}
        enableReinitialize
        validationSchema={schema}
        onSubmit={async (values) => {
          const result = await updateTask(values);
          if (result) setIsEditing(false);
        }}
      >
        {(formik) => (
          <Dialog
            open={open}
            TransitionProps={{ onExited: () => onClose() }}
            maxWidth="md"
            fullWidth
            classes={{ paper: classes.dialog }}
            onClose={() => setOpen(false)}
            PaperProps={{
              "aria-label": t("editDialog.title"),
            }}
          >
            <DialogTitle disableTypography className={classes.title}>
              {loading ? (
                <CustomSkeleton width="200px" height="30px" variant="rect" />
              ) : error ? (
                <></>
              ) : (
                <TitleField isEditing={isEditing} />
              )}
              <IconButton
                onClick={() => setOpen(false)}
                className={classes.close}
                aria-label={t("editDialog.close")}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent className={classes.content}>
              {error ? (
                <EmptyState
                  title={t("error.title")}
                  description={error}
                  icon={
                    <SmileySad fontSize="large" className={classes.errorIcon} />
                  }
                  className={classes.errorState}
                />
              ) : (
                <>
                  {loading || taggedTrendLoading ? (
                    <CustomSkeleton
                      height="254px"
                      variant="rect"
                      style={{ marginBottom: 18 }}
                    />
                  ) : (
                    <div
                      className={clsx({
                        [classes.chartContainer]: true,
                        [classes.chartContainerEditing]: isEditing,
                      })}
                    >
                      <TaskTrendChart
                        task={task}
                        taggedTrends={taggedTrends}
                        disabled={isEditing}
                      />
                    </div>
                  )}
                  {loading ? (
                    <Grid container>
                      <Grid item xs={12} md={8}>
                        <CustomSkeleton
                          height="300px"
                          variant="rect"
                          style={{ marginRight: 21 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomSkeleton height="300px" variant="rect" />
                      </Grid>
                    </Grid>
                  ) : (
                    <EditTaskForm
                      task={task}
                      isEditing={isEditing}
                      link={link}
                      actions={
                        canEdit && !isArchived ? (
                          <>
                            {showGenerateDeliveryTicket && (
                              <Button
                                onClick={() => setShowDeliveryTicket(true)}
                                variant="contained"
                                color="secondary"
                                startIcon={<SparklesSolid />}
                                data-testid="task-dialog-generate-delivery-ticket-button"
                                data-pendo="task-dialog-generate-delivery-ticket-button"
                              >
                                {t("actionsMenu.generateDeliveryTicket")}
                              </Button>
                            )}
                            <Button
                              onClick={() => setIsArchiving(true)}
                              ref={archiveButtonRef}
                              variant="outlined"
                              startIcon={<ArchiveSolid />}
                              data-testid="task-dialog-archive-button"
                              data-pendo="task-dialog-archive-button"
                            >
                              {t("editDialog.archiveTask")}
                            </Button>
                          </>
                        ) : undefined
                      }
                    />
                  )}
                </>
              )}
            </DialogContent>
            <DialogActions className={classes.actions}>
              <div className={classes.actionsItem}>
                {!isEditing && canEdit && !isArchived && (
                  <Button
                    onClick={() => setIsEditing(true)}
                    disabled={disabled}
                    variant="contained"
                    color="secondary"
                    startIcon={<PencilSolid />}
                    data-testid="task-dialog-edit-button"
                    data-pendo="task-dialog-edit-button"
                  >
                    {t("editDialog.editTask")}
                  </Button>
                )}
                {canEdit && (
                  <Button
                    onClick={() => setIsDeleting(true)}
                    ref={deleteButtonRef}
                    disabled={disabled}
                    variant="contained"
                    className={classes.deleteButton}
                    startIcon={<TrashSolid />}
                    data-testid="task-dialog-delete-button"
                    data-pendo="task-dialog-delete-button"
                  >
                    {t("editDialog.deleteTask")}
                  </Button>
                )}
              </div>
              <div className={classes.actionsItem}>
                {!isEditing ? (
                  <>
                    <Button
                      onClick={() => setOpen(false)}
                      variant="contained"
                      color="primary"
                      data-testid="task-dialog-done-button"
                      data-pendo="task-dialog-done-button"
                    >
                      {t("editDialog.done")}
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      onClick={() => {
                        setIsEditing(false);
                        formik.resetForm();
                      }}
                      variant="outlined"
                    >
                      {t("editDialog.cancel")}
                    </Button>
                    <Button
                      onClick={() => {
                        if (formik.isSubmitting) return;
                        formik.submitForm();
                      }}
                      loading={formik.isSubmitting}
                      variant="contained"
                      color="primary"
                      data-testid="task-dialog-update-button"
                      data-pendo="task-dialog-update-button"
                    >
                      {t("editDialog.updateTask")}
                    </Button>
                  </>
                )}
              </div>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
      {showDeliveryTicket && (
        <GenerateDeliveryTicketDialog
          taskId={taskId}
          onClose={() => setShowDeliveryTicket(false)}
        />
      )}
      {Boolean(isDeleting && deleteButtonRef.current) && (
        <DestructiveMenuActionConfirmation
          onConfirm={async () => {
            const result = await deleteTask();
            if (result) setOpen(false);
          }}
          onCancel={() => setIsDeleting(false)}
          anchorEl={deleteButtonRef.current}
          anchorOrigin={{ horizontal: "left", vertical: "top" }}
          transformOrigin={{ horizontal: "left", vertical: "bottom" }}
          title={t("actionsMenu.removeTitle")}
          description={t("actionsMenu.removeDescription")}
          confirmText={t("actionsMenu.removeButton")}
          cancelText={t("actionsMenu.cancelButton")}
        />
      )}
      {Boolean(isArchiving && archiveButtonRef.current) && !isArchived && (
        <DestructiveMenuActionConfirmation
          onConfirm={async () => {
            const result = await archiveTask();
            if (result) setOpen(false);
          }}
          onCancel={() => setIsArchiving(false)}
          anchorEl={archiveButtonRef.current}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          title={t("actionsMenu.archiveTitle")}
          description={t("actionsMenu.archiveDescription")}
          confirmText={t("actionsMenu.archiveButton")}
          cancelText={t("actionsMenu.cancelButton")}
        />
      )}
    </>
  );
}

function getTaskLink(
  task: CreateTaskFormValuesResult["task"],
  accountId: string,
): string | undefined {
  if (
    !task ||
    !task.reportTemplate ||
    !task.project.lastFinishedCrawl ||
    !task.reportType
  ) {
    return;
  }

  const isValidFilter =
    task.connectionFilter && !isNotEmptyConnectionFilter(task.connectionFilter);

  return Routes.Report.getUrl({
    accountId,
    projectId: getRawProjectId(task.project.id),
    crawlId: getRawCrawlId(task.project.lastFinishedCrawl.id),
    reportTemplateCode: task.reportTemplate.code,
    reportTypeCode: task.reportType.code.toLowerCase(),
    segmentId: task.segment?.id,
    filter: isValidFilter
      ? { _or: [task.connectionFilter] }
      : task.connectionFilter,
  });
}

const useStyles = makeStyles((theme) => ({
  dialog: {
    height: "100%",
  },
  title: {
    padding: theme.spacing(1.75, 3, 0.75, 3),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    display: "flex",
    alignItems: "flex-start",
  },
  close: {
    marginLeft: "auto",
    padding: "7px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2, 3, 0, 3),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  actions: {
    justifyContent: "space-between",
  },
  actionsItem: {
    display: "flex",
    gap: theme.spacing(1),
  },
  deleteButton: {
    color: theme.palette.red[700],
    backgroundColor: theme.palette.red[100],
    "&:hover": {
      backgroundColor: theme.palette.red[200],
    },
  },
  errorState: {
    height: "100%",
  },
  errorIcon: {
    color: theme.palette.red[400],
  },
  chartContainer: {
    minHeight: 254,
    background: theme.palette.grey[50],
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2.25),
    overflow: "hidden",
  },
  chartContainerEditing: {
    opacity: 0.54,
    pointerEvents: "none",
  },
}));
