/* eslint-disable fp/no-this, react/display-name */
import { useBrand, useTranslation } from "@lumar/shared";
import { Box, Theme, darken, makeStyles, useTheme } from "@material-ui/core";
import Highcharts from "highcharts";
import React from "react";
import { renderToString } from "react-dom/server";
import { useHistory } from "react-router-dom";
import { assert } from "../../assert";
import {
  ReportTemplateUnitFormatter,
  useReportTemplateUnitFormatter,
} from "../../locale/format-api-enum/useReportTemplateUnitFormatter";
import { useChartReportColorGetter } from "../chart-colors/useChartReportColors";
import { useChartDataContext } from "../components/chart-components/ChartDataContext";
import { ChartRef, HighchartsChart } from "../components/HighchartsChart";
import { VisualisationTypes } from "../types/ChartConfig";
import {
  useMultipointTooltipStyles,
  useTooltipStyles,
} from "../utils/useTooltipStyles";
import { MultiDimensionalSeriesChartProps } from "./MultiDimensionalSeriesChart";
import { MultipointTooltip } from "./MultipointTooltip";
import { PointTooltip } from "./PointTooltip";
import {
  getDataPointThreshold,
  isPointWithUrl,
  mapThresholdsToYAxisPlotlines,
} from "./utils";
import { hasUnitProperty } from "../utils/hasUnitProperty";
import { MultiDimensionalSeries } from "./ChartConfigItemMultiDimensionalSeries";

const useStyles = makeStyles({
  wrapper: {
    width: "100%",
    height: "100%",
  },
});

export const ColumnChart = React.forwardRef<
  ChartRef,
  MultiDimensionalSeriesChartProps
>(function Chart(
  {
    visualisationType,
    xAxisLabel,
    categories,
    multiDimensionalSeries: series,
    title,
    icon,
    visualisationColors,
    multipoint,
    tooltipTitle,
    legendEnabled = true,
    showXAxisLabel = true,
    showYAxisLabel = true,
    showPercentageInSeriesTooltip = true,
    showBorderAtColumnTop = false,
    presentLastValue = false,
    pointPadding,
    thresholds,
    categoryCsvColumnHeaderName,
    yAxisMax,
  },
  ref,
): JSX.Element {
  const theme = useTheme();
  const brand = useBrand();
  const classes = useStyles();
  const pointTooltipClasses = useTooltipStyles();
  const multipointTooltipClasses = useMultipointTooltipStyles();
  const history = useHistory();
  const { t } = useTranslation("units");
  const formatUnit = useReportTemplateUnitFormatter();

  const { crawl, project, segmentName } = useChartDataContext();
  const getReportColor = useChartReportColorGetter();
  const shouldApplyReportColors =
    !thresholds &&
    !visualisationColors &&
    brand.featureAvailability.chartReportSerieColor;

  const thresholdsPlotlines: Highcharts.YAxisPlotLinesOptions[] | undefined =
    thresholds ? mapThresholdsToYAxisPlotlines(thresholds) : undefined;

  const options: Highcharts.Options = {
    chart: {
      spacingTop: !presentLastValue ? 10 : 40,
      spacingRight: 5,
      spacingLeft: 5,
      spacingBottom: legendEnabled ? undefined : 10,
      animation: { duration: 1000 },
      events: {
        render: function renderColumnChart() {
          if (showBorderAtColumnTop) {
            drawTopBorder(this);
          }
          if (presentLastValue) {
            drawLastValue(this, thresholds ?? [], theme, formatUnit, series);
          }
        },
      },
    },
    xAxis: {
      visible: showXAxisLabel,
      type: "category",
      title: {
        text: xAxisLabel,
      },
      categories,
    },
    yAxis: {
      plotLines: thresholdsPlotlines,
      // We want to show the plot lines when provided
      // but setting `visible: false` makes them disappear.
      // Changing `formatter` function, tick and grid lengths and widths
      // keeps the plot lines and allows us to hide everything else.
      tickLength: showYAxisLabel ? undefined : 0,
      tickWidth: showYAxisLabel ? undefined : 0,
      minorTickLength: showYAxisLabel ? undefined : 0,
      minorTickWidth: showYAxisLabel ? undefined : 0,
      gridLineWidth: showYAxisLabel ? undefined : 0,
      minorGridLineWidth: showYAxisLabel ? undefined : 0,
      min: 0,
      max: yAxisMax,
      title: {
        text: null,
      },
      labels: {
        formatter: showYAxisLabel
          ? visualisationType === VisualisationTypes.FullStackedColumn
            ? function () {
                // eslint-disable-next-line fp/no-this
                return `${this.value}%`;
              }
            : undefined
          : function () {
              return "";
            },
      },
    },
    title: {
      margin: !presentLastValue ? undefined : 40,
    },
    series: series.map((serie) => {
      const color = shouldApplyReportColors
        ? getReportColor(serie.reportTemplateCode)
        : undefined;
      return {
        ...serie,
        type: "column",
        color,
        ...(pointPadding !== undefined ? { pointPadding } : {}),
        data: serie.data.map(({ x, ...dataPoint }) => {
          const color = thresholds
            ? getDataPointThreshold(dataPoint.y, thresholds)?.color
            : undefined;
          if (categories) {
            return {
              ...dataPoint,
              color,
            };
          }
          assert(typeof x !== "string");
          return {
            ...dataPoint,
            color,
            x: x instanceof Date ? x.getTime() : x,
          };
        }),
      };
    }),
    plotOptions: {
      column: {
        minPointLength: 3,
        borderRadius: 0,
        stacking:
          visualisationType === VisualisationTypes.StackedColumn ||
          visualisationType === VisualisationTypes.Column
            ? "normal"
            : "percent",
      },
      series: {
        cursor: "pointer",
        point: {
          events: {
            click: function (event) {
              if (!multipoint) {
                const point = (event.point ?? event.target) as Highcharts.Point;

                if (isPointWithUrl(point) && point.url) {
                  const { url } = point;

                  if (event.ctrlKey || event.metaKey) {
                    window.open(url, "_blank", "noreferrer");
                  } else {
                    history.push(url);
                  }
                }
              }
            },
          },
        },
      },
    },
    tooltip: {
      shared: multipoint ? true : undefined,
      stickOnContact: multipoint ? true : undefined,
      borderRadius: 8,
      formatter: function () {
        return renderToString(
          multipoint ? (
            <MultipointTooltip
              classes={multipointTooltipClasses}
              formatter={this}
              formatTitle={tooltipTitle}
              t={t}
            />
          ) : (
            <PointTooltip
              formatter={this}
              showPercentageInSeriesTooltip={showPercentageInSeriesTooltip}
              classes={pointTooltipClasses}
              t={t}
              formatUnit={formatUnit}
            />
          ),
        );
      },
    },
    legend: {
      enabled: legendEnabled,
      itemMarginBottom: 7,
      itemStyle: {
        width: 250,
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
    },
    exporting: {
      csv: {
        columnHeaderFormatter: (_: unknown, key: string) => {
          if (!categoryCsvColumnHeaderName) {
            return false;
          }
          if (!key) {
            return categoryCsvColumnHeaderName;
          }
          return false;
        },
      },
      chartOptions: {
        chart: {
          events: {
            render: function renderColumnChart() {
              if (showBorderAtColumnTop) {
                // Setting animation duration to 0 so the export doesn't include the top bar in the middle of its animation.
                drawTopBorder(this, 0);
              }
              if (presentLastValue) {
                drawLastValue(
                  this,
                  thresholds ?? [],
                  theme,
                  formatUnit,
                  series,
                );
              }
            },
          },
          spacingTop: 20,
          spacingRight: 20,
          spacingLeft: 20,
          spacingBottom: legendEnabled ? undefined : 40,
        },
      },
    },
  };

  if (visualisationColors) {
    // eslint-disable-next-line fp/no-mutation
    options.colors = visualisationColors;
  }

  return (
    <Box flexGrow={1} className={classes.wrapper}>
      <HighchartsChart
        options={options}
        ref={ref}
        title={title}
        icon={icon}
        segmentName={segmentName}
        projectName={project?.name}
        primaryDomain={project?.primaryDomain}
        crawlCreatedAt={crawl?.createdAt}
      />
    </Box>
  );
});

type ColumnChartPoint = Highcharts.Point & {
  topBorder?: Highcharts.SVGElement; // custom field to store top border reference
  barX?: number; // exists in runtime
};
type ColumnChartSeries = Highcharts.Series & {
  barW?: number; // exists in runtime
};

function drawTopBorder(
  chart: Highcharts.Chart,
  animationDuration = 1000,
): void {
  const series: ColumnChartSeries[] = chart.series;
  series.forEach((series) => {
    series.points.forEach((point: ColumnChartPoint) => {
      const x = chart.plotLeft + (point.barX ?? 0) + 0.5;
      const y = chart.plotTop + (point.plotY ?? 0);
      // Using magic number to reduce the Highcharts rounding bug. Not ideal.
      const width = series.barW ? series.barW - 1 : 0;
      const color = typeof point.color === "string" ? point.color : "#ffffff";
      if (!point.topBorder) {
        // eslint-disable-next-line fp/no-mutation
        point.topBorder = chart.renderer
          .rect(x, chart.plotHeight + 8, width, 2)
          .attr({
            fill: darken(color, 0.1),
            zIndex: 3,
          })
          .animate({ y }, { duration: animationDuration })
          .add();
      } else if (series.visible) {
        point.topBorder.attr({ x, y, width });
      } else {
        point.topBorder.attr({ width: 0 });
      }
    });
  });
}

function drawLastValue(
  chart: Highcharts.Chart,
  thresholds: {
    value: number;
    color: string;
    textColor: string;
  }[],
  theme: Theme,
  formatUnit: ReportTemplateUnitFormatter,
  series: MultiDimensionalSeries,
): void {
  const x = chart.plotLeft;
  const y = chart.plotTop - 10;
  const lastSeries = series[series.length - 1];
  const lastSeriesDatum =
    lastSeries.data[series[series.length - 1].data.length - 1];
  const lastValue = lastSeriesDatum.y;
  const lastValueUnit = hasUnitProperty(lastSeries) ? lastSeries.unit : "";
  chart.renderer
    .text(formatUnit(lastValue, lastValueUnit), x, y)
    .css({
      fontFamily: theme.typography.fontFamily,
      lineHeight: theme.typography.pxToRem(20),
      fontSize: theme.typography.pxToRem(24),
      fontWeight: "600",
      color: getDataPointThreshold(lastValue, thresholds ?? [])?.textColor,
    })
    .add();
}
