import { LegacyTaskPriority, useAccountTasksQuery } from "../../graphql";
import { Task } from "./types";
import { useParams } from "react-router-dom";

interface AccountTasksData {
  tasks: Task[];
  error: boolean | undefined;
  loading: boolean;
  allTaskCount: number;
  hasMore: boolean;
  fetchMore: () => void;
}

export function useAccountTasks({
  search,
}: {
  search: string;
}): AccountTasksData {
  const { accountId } = useParams<{ accountId: string }>();

  const { data, error, loading, fetchMore } = useAccountTasksQuery({
    variables: {
      accountId,
      filter: {
        fixedAt: { isNull: true },
        ...(search ? { title: { contains: search } } : {}),
      },
    },
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });

  return {
    loading,
    error: Boolean(error),
    tasks:
      data?.getAccount?.legacyTasks.edges
        .map((x) => x.node)
        // Note: In order to avoid refetching every page when a task is updated,
        // tasks are sorted by priority to maintain the correct order.
        .toSorted(
          (a, b) => getPriorityOrder(a.priority) - getPriorityOrder(b.priority),
        ) || [],
    allTaskCount: data?.getAccount?.allTasks.totalCount ?? 0,
    hasMore: data?.getAccount?.legacyTasks.pageInfo.hasNextPage || false,
    fetchMore: () =>
      fetchMore({
        variables: { after: data?.getAccount?.legacyTasks.pageInfo.endCursor },
      }),
  };
}

function getPriorityOrder(priority: LegacyTaskPriority): number {
  switch (priority) {
    case LegacyTaskPriority.Critical:
      return 1;
    case LegacyTaskPriority.High:
      return 2;
    case LegacyTaskPriority.Medium:
      return 3;
    case LegacyTaskPriority.Low:
      return 4;
    case LegacyTaskPriority.Note:
      return 5;
    default:
      return Number.MAX_SAFE_INTEGER;
  }
}
