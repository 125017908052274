import { filterReportMetrics } from "../../../../_common/report-metrics/filterReportMetrics";
import {
  CustomMetricType,
  CustomReportColumnsMetadataQuery,
  ReportStatColumnsMetadataQuery,
} from "../../../../graphql";
import { convertCutomMetricTypeToMetricType } from "../../../../resource-detail/data/getMetrics";
import { MetricData, MetricGrouping } from "../ReportGrid.types";
import { ReportGridColumn } from "./ReportGridColumns.types";

type ReportData =
  | ReportStatColumnsMetadataQuery
  | CustomReportColumnsMetadataQuery
  | undefined;

type Props = {
  data: ReportData;
  isGridView: boolean;
  metricsGroupingsOverwrite?: Array<MetricGrouping>;
};

type Result = {
  allMetrics: ReportGridColumn[];
  filterMetrics: ReportGridColumn[];
  cardMetrics: ReportGridColumn[];
  foundInSourcesMetrics: ReportGridColumn[];
  defaultMetrics: ReportGridColumn[];
};

// Those indexes have an order guaranteed by the api.
const CARD_METRICS_INDEX = 0;
const FOUND_IN_SOURCES_INDEX = 3;

export function getReportMetrics({
  data,
  isGridView,
  metricsGroupingsOverwrite,
}: Props): Result {
  const reportTemplate = data?.report?.reportTemplate;
  const customExtractions =
    data?.getCrawl?.crawlSetting?.customExtractions?.map((x) => ({
      code: x.reportTemplateCode.replaceAll("_", "").toLowerCase(),
      label: x.label,
    })) ?? [];

  const allMetrics = getAllMetrics(data, customExtractions);

  const metricsGroupings =
    (metricsGroupingsOverwrite ?? reportTemplate?.metricsGroupings)?.map(
      (group) => ({
        metrics: group.metrics.reduce<MetricData[]>((result, { code }) => {
          const metric = allMetrics.find((x) => x.code === code);
          return metric ? [...result, metric] : result;
        }, []),
      }),
    ) || [];

  const cardMetrics = metricsGroupings[CARD_METRICS_INDEX]?.metrics ?? [];

  const foundInSourcesMetrics =
    metricsGroupings[FOUND_IN_SOURCES_INDEX]?.metrics ?? [];

  const defaultMetrics =
    metricsGroupings
      .filter((_v, i) => {
        if (isGridView) return true;
        return i !== CARD_METRICS_INDEX && i !== FOUND_IN_SOURCES_INDEX;
      })
      .flatMap((g) => g.metrics) ?? [];

  const filterMetrics = filterReportMetrics<MetricData>(
    allMetrics,
    customExtractions.map((x) => x.code),
  );

  return {
    allMetrics,
    cardMetrics,
    foundInSourcesMetrics,
    defaultMetrics,
    filterMetrics,
  };
}

function getAllMetrics(
  data: ReportData,
  customExtractions: {
    code: string;
    label: string;
  }[],
): MetricData[] {
  const metrics = data?.report?.reportTemplate.datasource.metrics || [];
  const moduleCode = data?.report?.project.moduleCode;

  return metrics
    .flatMap((metric) => {
      if (metric.code === "customMetrics") {
        const customMetricData = data?.getCrawl?.customMetrics || [];
        return customMetricData.map<MetricData>((x) => ({
          code: `customMetrics.${x.code}`,
          name: `${x.name} *`,
          description: "",
          type: convertCutomMetricTypeToMetricType(x.type),
          connectionPredicates: x.connectionPredicates ?? [],
          supportedModules: metric.supportedModules,
          isCustomMetricSortable: isCutomMetricTypeSortable(x.type),
        }));
      }

      if (metric.code.startsWith("customExtraction")) {
        const customName = customExtractions.find(
          (x) => x.code === metric.code.toLowerCase(),
        )?.label;

        return !customName
          ? metric
          : {
              ...metric,
              name: customName,
              description: metric.description.replace(metric.name, customName),
            };
      }

      return [metric];
    })
    .filter((x) => moduleCode && x.supportedModules?.includes(moduleCode));
}

function isCutomMetricTypeSortable(type?: CustomMetricType | null): boolean {
  switch (type) {
    case CustomMetricType.Boolean:
    case CustomMetricType.Integer:
    case CustomMetricType.Number:
    case CustomMetricType.String:
      return true;
    default:
      return false;
  }
}
