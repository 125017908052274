import { createSvgIcon } from "@lumar/shared";

export const PriorityHighIcon = createSvgIcon(
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 12.606L4.84322 13.4167L10.0079 8.59753L10.5587 9.11159L10.5557 9.10876L15.1441 13.3907L16 12.5919C14.7318 11.4085 11.1913 8.10437 10.0079 7C9.12833 7.82023 9.98547 7.02034 4 12.606Z"
      fill="#DC2626"
    />
    <path
      d="M4 12.606L4.84322 13.4167L10.0079 8.59753L10.5587 9.11159L10.5557 9.10876L15.1441 13.3907L16 12.5919C14.7318 11.4085 11.1913 8.10437 10.0079 7C9.12833 7.82023 9.98547 7.02034 4 12.606"
      stroke="#DC2626"
      strokeWidth="0.5"
      fill="none"
    />
  </>,
  "PriorityHighIcon",
  { viewBox: "0 0 20 20" },
);
