import React from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "@lumar/shared";
import { Task } from "../../data/types";

export function UnresolvedPercentCell({ task }: { task: Task }): JSX.Element {
  const { t } = useTranslation("units");

  const value = Boolean(task.identified)
    ? Math.ceil(((task.remaining || 0) / (task.identified || 0)) * 100)
    : 0;

  return (
    <Typography variant="body2">
      {t("percent", { count: (value as number) / 100 })}
    </Typography>
  );
}
