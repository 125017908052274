const AGGREGATES = ["sum", "avg", "count"];

export function formatMetrics(
  row: Record<string, Record<string, unknown>>,
): Record<string, unknown> {
  return AGGREGATES.reduce(
    (result, aggregate) => ({
      ...result,
      ...Object.fromEntries(
        Object.entries(row?.[aggregate] || {})
          .filter((x) => x[0] !== "__typename")
          .map(([key, value]) => [`${key}-${aggregate}`, value]),
      ),
    }),
    {},
  );
}
