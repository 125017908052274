import React from "react";
import { Chip, encodeConnectionFilterSearchParam } from "@lumar/shared";
import { Grid, makeStyles, MenuItem, Tooltip } from "@material-ui/core";
import { isEmpty } from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import { fromRestFilter } from "@deepcrawl/connection-filters";
import { ConnectionFilter } from "../../../../../../_common/connection-filtering/types";
import { isNotEmptyConnectionFilter } from "../../../../../../_common/connection-filtering/isNotEmptyConnectionFilter";
import { LegacyTask, LegacyTaskPriority } from "../../../../../../graphql";

const useStyles = makeStyles((theme) => {
  return {
    [LegacyTaskPriority.Low]: {
      background: theme.palette.grey[300],
      color: theme.palette.grey[800],
    },
    [LegacyTaskPriority.Note]: {
      background: theme.palette.grey[300],
      color: theme.palette.grey[800],
    },
    [LegacyTaskPriority.Medium]: {
      background: theme.palette.yellow[400],
      color: theme.palette.grey[800],
    },
    [LegacyTaskPriority.High]: {
      background: theme.palette.orange[500],
      color: "white",
    },
    [LegacyTaskPriority.Critical]: {
      background: theme.palette.red[500],
      color: theme.palette.grey[800],
    },
  };
});

type Task = Pick<LegacyTask, "id" | "title" | "priority" | "filters">;

export function TaskItem({ task }: { task: Task }): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const { pathname, search } = useLocation();

  const getFilter = (task: Task): string => {
    const fromRest = fromRestFilter(task.filters);
    return !isNotEmptyConnectionFilter(task.filters)
      ? encodeConnectionFilterSearchParam({
          _or: [fromRest],
        } as ConnectionFilter)
      : encodeConnectionFilterSearchParam(task.filters);
  };

  const onClick = (task: Task): void => {
    const params = new URLSearchParams(search);
    if (!isEmpty(task.filters)) {
      params.set("filter", getFilter(task));
    } else {
      params.delete("filter");
    }
    history.push({ pathname, search: `${params}` });
  };

  return (
    <MenuItem
      key={task.id}
      onClick={() => onClick(task)}
      data-testid="report-grid-task"
    >
      <Grid container>
        <Grid item xs={8}>
          <Truncate value={task.title} />
        </Grid>
        <Grid item xs={4} container justifyContent="flex-end">
          <Chip
            label={task.priority}
            color="red"
            style={{ fontWeight: 500 }}
            className={classes[task.priority]}
          ></Chip>
        </Grid>
      </Grid>
    </MenuItem>
  );
}

function Truncate({ value }: { value: string }): JSX.Element {
  const ref = React.useRef<HTMLSpanElement | null>(null);
  const [truncated, setTruncated] = React.useState(false);

  React.useEffect(() => {
    if (!ref.current) return;

    const truncatedNew = ref.current.scrollWidth > ref.current.offsetWidth;
    if (truncatedNew !== truncated) setTruncated(truncatedNew);
  }, [truncated]);

  return (
    <Tooltip title={truncated ? value : ""}>
      <span
        style={{
          overflow: "hidden",
          display: "block",
          textOverflow: "ellipsis",
        }}
        ref={ref}
      >
        {value}
      </span>
    </Tooltip>
  );
}
