import { ApolloError, getRawCrawlId, useTranslation } from "@lumar/shared";
import React from "react";
import {
  DatasourceCode,
  GetCompareCrawlQuery,
  ReportTypeCode,
  useGetCompareCrawlQuery,
} from "../../../graphql";
import { getMetrics } from "../../data/getMetrics";
import { QueryReturnValue, ResourceDetailData } from "../../data/types";
import { useResourceDetailParams } from "../../data/useResourceDetailParams";
import { CompareCrawlData, GetCompareCrawlError } from "./types";
import { getResourceDatasources } from "../../datasources/getDatasourceView";
import { uniq } from "lodash";
import { insertManyIf } from "../../../_common/insertIf";

const datasourceTransition = [
  DatasourceCode.CrawlUrls,
  DatasourceCode.CrawlUncrawledUrls,
];

export function useGetCompareCrawlData({
  currentCrawlDate,
  sourceTemplate,
  crawlId,
}: {
  currentCrawlDate: Date;
  sourceTemplate: ResourceDetailData["sourceTemplate"];
  crawlId?: string;
}): QueryReturnValue<CompareCrawlData, GetCompareCrawlError> {
  const params = useResourceDetailParams();

  const crawlFilter = crawlId
    ? { id: { eq: crawlId } }
    : { createdAt: { lt: currentCrawlDate.toISOString() } };

  const reportTemplateCodes = uniq([
    sourceTemplate.code,
    ...insertManyIf(
      datasourceTransition.includes(sourceTemplate.datasource.datasourceCode),
      getResourceDatasources()
        .filter((x) => datasourceTransition.includes(x.datasource))
        .map((x) => x.templateCode),
    ),
  ]);

  const {
    data: queryData,
    loading,
    error: queryError,
  } = useGetCompareCrawlQuery({
    variables: {
      projectId: params.projectId,
      crawlFilter,
      reportInput: {
        reportTemplateCodes,
        reportTypeCodes: [ReportTypeCode.Basic],
        segmentId: null,
      },
      resourceId: params.resourceId,
    },
    fetchPolicy: "cache-first",
  });

  const result = useFormattedData({
    data: queryData,
    error: queryError,
    sourceTemplate,
  });

  if (loading) return { loading: true };
  if (result.error !== undefined)
    return {
      loading: false,
      error: result.error,
    };

  return {
    loading: false,
    data: result.data,
  };
}

function useFormattedData({
  data,
  error,
  sourceTemplate,
}: {
  data?: GetCompareCrawlQuery;
  error?: ApolloError;
  sourceTemplate: ResourceDetailData["sourceTemplate"];
}):
  | { data: CompareCrawlData; error?: undefined }
  | { data?: undefined; error: GetCompareCrawlError } {
  const { t } = useTranslation("resourceDetail");

  return React.useMemo(() => {
    if (error) {
      return {
        error: {
          message: error.message,
          isArchived: checkErrorCode(
            error,
            "REPORT_DATA_UNAVAILABLE_CRAWL_ARCHIVED",
          ),
          isUnarchivig: checkErrorCode(
            error,
            "REPORT_DATA_UNAVAILABLE_CRAWL_UNARCHIVING",
          ),
        },
      };
    }

    const project = data?.getProject;
    const crawl = project?.crawls.nodes[0];
    const resource = crawl?.reportsByCode.find(
      (x) => x.reportTemplate.code === sourceTemplate.code,
    )?.getResource;

    if (!project || !crawl || !resource) {
      if (datasourceChangedFromCrawledToUncrawled(crawl, sourceTemplate)) {
        return {
          error: {
            message: t("errorCompareDatasourceChangedCrawledUncrawled"),
            isDatasourceChanged: true,
          },
        };
      }

      if (datasourceChangedFromUncrawledToCrawled(crawl, sourceTemplate)) {
        return {
          error: {
            message: t("errorCompareDatasourceChangedUncrawledCrawled"),
            isDatasourceChanged: true,
          },
        };
      }

      if (!crawl && !resource) {
        return {
          error: {
            message: t("noPriorCrawl"),
            noPriorCrawl: true,
          },
        };
      }

      return { error: { message: t("compareResourceNotFound") } };
    }

    const metrics = getMetrics({
      resource,
      datasource: sourceTemplate.datasource,
      moduleCode: project.moduleCode,
      customMetrics: crawl.customMetrics,
    });

    return {
      data: {
        metrics,
        crawlId: getRawCrawlId(crawl.id),
      },
    };
  }, [data, error, t, sourceTemplate]);
}

function checkErrorCode(error: ApolloError, code: string): boolean {
  return Boolean(error.graphQLErrors.find((x) => x.extensions?.code === code));
}

function datasourceChangedFromUncrawledToCrawled(
  crawl:
    | NonNullable<GetCompareCrawlQuery["getProject"]>["crawls"]["nodes"][0]
    | undefined,
  sourceTemplate: ResourceDetailData["sourceTemplate"],
): boolean {
  const isCrawledDatasource =
    sourceTemplate.datasource.datasourceCode === DatasourceCode.CrawlUrls;
  const uncrawledResource = crawl?.reportsByCode.find(
    (x) =>
      x.reportTemplate.datasource.datasourceCode ===
        DatasourceCode.CrawlUncrawledUrls && x.getResource,
  )?.getResource;

  return isCrawledDatasource && Boolean(uncrawledResource);
}

function datasourceChangedFromCrawledToUncrawled(
  crawl:
    | NonNullable<GetCompareCrawlQuery["getProject"]>["crawls"]["nodes"][0]
    | undefined,
  sourceTemplate: ResourceDetailData["sourceTemplate"],
): boolean {
  const isUncrawledDatasource =
    sourceTemplate.datasource.datasourceCode ===
    DatasourceCode.CrawlUncrawledUrls;
  const crawledResource = crawl?.reportsByCode.find(
    (x) =>
      x.reportTemplate.datasource.datasourceCode === DatasourceCode.CrawlUrls &&
      x.getResource,
  )?.getResource;

  return isUncrawledDatasource && Boolean(crawledResource);
}
