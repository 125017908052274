import React from "react";
import { makeStyles, SvgIcon, Tooltip, useTheme } from "@material-ui/core";
import { TooltipInfoIcon, Typography } from "@lumar/shared";
import { kebabCase } from "lodash";
import { useReportTemplateUnitFormatter } from "../../../../../_common/locale/format-api-enum/useReportTemplateUnitFormatter";
import { useChartReportColorGetter } from "../../../../../_common/charts/chart-colors/useChartReportColors";
import { getThresholds } from "../../../../../_common/charts/small-column-chart/SmallColumnChart";
import { sortBy } from "lodash";
import { useChartDataContext } from "../../../../../_common/charts/components/chart-components/ChartDataContext";
import { useCrawlContextData } from "../../../../CrawlContext";
import { Link } from "react-router-dom";
import sanitizeHtml from "sanitize-html";

interface TileChartPanelTitleProps {
  title: string;
  description?: React.ReactNode;
  descriptionTitle?: string;
  unitDescription?: string;
  icon?: typeof SvgIcon;
  testAttributePrefix?: string;
  link?: string;
}

export function TileChartPanelTitle({
  title,
  description,
  descriptionTitle,
  unitDescription,
  icon: Icon,
  testAttributePrefix,
  link,
}: TileChartPanelTitleProps): JSX.Element {
  const classes = useStyles();
  const titleTestAttribute = `${testAttributePrefix || kebabCase(title.toLowerCase())}-title`;
  const theme = useTheme();
  const formatUnit = useReportTemplateUnitFormatter();
  const getReportColor = useChartReportColorGetter();

  const { reportStats, getReportUnit, getAggregatedMetric } =
    useChartDataContext();
  const { crawlSetting } = useCrawlContextData();

  const report = reportStats[0];
  const series =
    sortBy(report?.trend || [], (x) =>
      x.createdAt ? new Date(x.createdAt).getTime() : 0,
    ) || [];

  const metric = getAggregatedMetric(report);

  const thresholds = getThresholds({ metric, crawlSetting, theme });

  const lastValue = series[series.length - 1]?.basic;

  const oldValue = series[series.length - 2]?.basic;

  const signProps = useSignProps(lastValue, oldValue);

  function getThresholdColor(
    value: number | null | undefined,
  ): string | undefined {
    if (!thresholds?.length) return getReportColor(report?.reportTemplateCode);

    if (value === null || value === undefined) {
      return theme.palette.grey[700];
    }

    const idx = thresholds.reduce(
      (result, treshold, idx) => (value > treshold.value ? idx : result),
      0,
    );
    return thresholds[idx].color;
  }

  return (
    <div className={classes.root}>
      <div className={classes.iconHolder}>
        {Icon && <Icon className={classes.icon} />}
      </div>
      <div className={classes.titleHolder}>
        <div className={classes.wrapper}>
          {link ? (
            <Link
              to={link}
              style={{
                color: "inherit",
                textDecoration: "none",
                whiteSpace: "nowrap",
              }}
            >
              <Typography
                variant="h6Medium"
                data-testid={titleTestAttribute}
                noWrap
                color="textPrimary"
                className={classes.title}
              >
                {title}
              </Typography>
            </Link>
          ) : (
            <Typography
              variant="h6Medium"
              data-testid={titleTestAttribute}
              noWrap
              color="textPrimary"
              className={classes.title}
            >
              {title}
            </Typography>
          )}
          {Boolean(description) && (
            <Tooltip
              title={
                <>
                  <Typography
                    component="h6"
                    variant="subtitle3Medium"
                    className={classes.tooltipTitle}
                  >
                    {descriptionTitle || title}
                  </Typography>
                  {typeof description === "string" ? (
                    <Typography
                      variant="caption"
                      style={{ whiteSpace: "break-spaces" }}
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(description),
                      }}
                    />
                  ) : (
                    <Typography
                      variant="caption"
                      style={{ whiteSpace: "break-spaces" }}
                    >
                      {description}
                    </Typography>
                  )}
                </>
              }
              arrow
              placement="top"
              interactive
              classes={{ tooltip: classes.tooltip }}
            >
              <TooltipInfoIcon className={classes.tooltipIcon} />
            </Tooltip>
          )}
        </div>
        <Typography
          variant="h6Medium"
          data-testid={titleTestAttribute}
          noWrap
          color="textPrimary"
          className={classes.unit}
        >
          {unitDescription ?? ""}
        </Typography>
      </div>
      <div className={classes.values}>
        <Typography
          style={{
            display: "block",
            lineHeight: theme.typography.pxToRem(20),
            fontSize: theme.typography.pxToRem(18),
            fontWeight: 600,
            color: getThresholdColor(lastValue),
          }}
        >
          {lastValue === null || lastValue === undefined
            ? "-"
            : formatUnit(lastValue, getReportUnit(report))}
        </Typography>

        <Typography
          style={{
            display: "block",
            lineHeight: theme.typography.pxToRem(14),
            fontSize: theme.typography.pxToRem(12),
            fontWeight: 600,
            color: signProps.color,
          }}
        >
          {signProps.value === null
            ? signProps.sign
            : signProps.sign +
              formatUnit(signProps.value, getReportUnit(report))}
        </Typography>
      </div>
    </div>
  );
}

function useSignProps(
  value: number | null | undefined,
  oldValue: number | null | undefined,
): {
  sign: string;
  value: number | null;
  color: string;
} {
  const theme = useTheme();

  if (
    value === null ||
    value === undefined ||
    oldValue === null ||
    oldValue === undefined
  ) {
    return { sign: "-", value: null, color: theme.palette.grey[700] };
  }

  const diff = Math.ceil((value - oldValue) * 100) / 100;

  if (diff > 0)
    return {
      sign: "+",
      value: diff,
      color: theme.palette.red[700],
    };
  if (diff < 0)
    return {
      sign: "-",
      value: -diff,
      color: theme.palette.green[700],
    };

  return { sign: "", value: 0, color: theme.palette.grey[700] };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "start",
    padding: theme.spacing(0, 0.75, 0, 0.75),
  },
  titleHolder: {
    flexGrow: 1,
    flexBasis: 0,
    overflow: "hidden",
  },
  iconHolder: {
    alignSelf: "center",
  },
  title: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(20),
    color: theme.palette.grey[800],
    fontWeight: 600,
    margin: theme.spacing(0, 0, 0, 0),
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  tooltip: {
    padding: theme.spacing(1.5, 1.5, 2, 1.5),
    minWidth: 490,
    maxWidth: 490,
  },
  tooltipIcon: {
    fontSize: theme.typography.pxToRem(15),
    fill: theme.palette.grey[400],
    marginRight: theme.spacing(0.375),
    marginLeft: theme.spacing(0.25),
  },
  tooltipTitle: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1),
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  unit: {
    fontSize: theme.typography.pxToRem(10),
    fontWeight: 400,
    lineHeight: theme.typography.pxToRem(15),
    color: theme.palette.grey[500],
  },
  values: {
    display: "flex",
    flexFlow: "column",
    alignItems: "end",
  },
  icon: {
    width: 18,
    height: 18,
    marginRight: theme.spacing(0.75),
  },
}));
