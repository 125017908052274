import { ApolloError, useTranslation } from "@lumar/shared";
import React from "react";
import {
  GetResourceDetailDataQuery,
  ReportTypeCode,
  useGetResourceDetailDataQuery,
} from "../../graphql";
import {
  getDatasourceView,
  getResourceDatasources,
} from "../datasources/getDatasourceView";
import { getMetrics } from "./getMetrics";
import { DatasourceView, QueryReturnValue, ResourceDetailData } from "./types";
import { useResourceDetailParams } from "./useResourceDetailParams";

export function useResourceDetailData(): QueryReturnValue<
  ResourceDetailData & { view: DatasourceView }
> {
  const params = useResourceDetailParams();

  const reportTemplateCodes = params.sourceReportTemplateCode
    ? [params.sourceReportTemplateCode]
    : getResourceDatasources().map((x) => x.templateCode);

  const {
    data: queryData,
    loading,
    error: queryError,
  } = useGetResourceDetailDataQuery({
    variables: {
      crawlId: params.crawlId,
      reportInput: {
        reportTemplateCodes,
        reportTypeCodes: [ReportTypeCode.Basic],
        segmentId: null,
      },
      resourceId: params.resourceId,
      navigationReportTemplateCode: getReportTemplateCode(
        params.reportTemplateCodeWithTypeCode,
      ),
    },
    fetchPolicy: "cache-first",
  });

  const result = useFormattedData({
    data: queryData,
    error: queryError,
  });

  if (loading) return { loading: true };
  if (result.error !== undefined)
    return {
      loading: false,
      error: result.error,
    };

  return {
    loading: false,
    data: result.data,
  };
}

function useFormattedData({
  data,
  error,
}: {
  data?: GetResourceDetailDataQuery;
  error?: ApolloError;
}):
  | { data: ResourceDetailData & { view: DatasourceView }; error?: undefined }
  | { data?: undefined; error: string } {
  const { t } = useTranslation("resourceDetail");

  return React.useMemo(() => {
    if (error) {
      return { error: getErrorMessage(error) || t("genericError") };
    }

    const crawl = data?.getCrawl;
    const report = data?.getCrawl?.reportsByCode.find((x) => x.getResource);
    const attachments = report?.crawlUrls?.edges[0]?.node?.attachments ?? [];
    const resource = report?.getResource;
    const settings = crawl?.crawlSetting;

    if (!data || !crawl || !report || !settings)
      return { error: t("errorReportDataUnavailable") };

    const datasource = report.reportTemplate.datasource;
    const view = getDatasourceView({
      datasource: datasource.datasourceCode,
      moduleCode: crawl.project.moduleCode,
      resource: report.getResource,
    });

    if (!view) return { error: t("errorDatasourceNotSupported") };

    const metrics = getMetrics({
      resource,
      datasource,
      moduleCode: crawl.project.moduleCode,
      settings,
      customMetrics: crawl.customMetrics,
    });

    return {
      data: {
        view,
        metrics,
        attachments,
        moduleCode: crawl.project.moduleCode,
        sourceTemplate: {
          code: report.reportTemplate.code,
          datasource,
        },
        navigationReportTemplate: data.getReportTemplates.nodes[0],
        crawl: {
          createdAt: new Date(crawl.createdAt),
          crawlTypes: crawl.crawlTypes,
          settings,
        },
      },
    };
  }, [data, error, t]);
}

function getReportTemplateCode(reportTemplateCodeWithTypeCode: string): string {
  const idx = reportTemplateCodeWithTypeCode.lastIndexOf("_");
  return idx >= 0
    ? reportTemplateCodeWithTypeCode.substring(0, idx)
    : reportTemplateCodeWithTypeCode;
}

function getErrorMessage(error: ApolloError): string | undefined {
  if (error.graphQLErrors[0]) return error.graphQLErrors[0].message;
  if (error.clientErrors?.[0]) return error.clientErrors[0].message;
  if (error.protocolErrors?.[0]) return error.protocolErrors[0].message;
  if (error.networkError) return error.networkError.message;
}
