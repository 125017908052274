import React from "react";
import { useCrawlContextData } from "../../../../crawl-overview/CrawlContext";
import { useSearchParam } from "../../../routing/useSearchParam";
import { ChartConfigItem, TileChartConfigItem } from "../../types/ChartConfig";
import { isChartMissingRequiredSource } from "../utils";
import { ChartDataContext } from "./ChartDataContext";
import { getAggregatedMetric, getReportUnit } from "./chartDataHelpers";
import { orderBy } from "lodash";
import { useQuery } from "@lumar/shared";
import { ChartConfigGenericReportStat } from "../../types/ChartConfigItemBase";
import * as Yup from "yup";
import { REPORT_STAT_TREND_ITEM_LIMIT } from "../../../constants";

type ChartComponentProps = (ChartConfigItem | TileChartConfigItem) & {
  includeMultipleCrawlTypes?: boolean;
  noTrendsTemplate?: React.ReactElement;
  noReportsTemplate?: React.ReactElement;
  children?: React.ReactNode;
};

type ChartUsingCrawlContextComponentProps = (
  | ChartConfigItem
  | TileChartConfigItem
) & {
  inputSource: "CrawlContext";
  includeMultipleCrawlTypes?: boolean;
  noTrendsTemplate?: React.ReactElement;
  noReportsTemplate?: React.ReactElement;
  children?: React.ReactNode;
};

type ChartUsingGqlQueryComponentProps = (
  | ChartConfigItem
  | TileChartConfigItem
) & {
  inputSource: "GqlQuery";
  includeMultipleCrawlTypes?: boolean;
  noTrendsTemplate?: React.ReactElement;
  noReportsTemplate?: React.ReactElement;
  children?: React.ReactNode;
};

const REPORT_STATS_LIMIT_DEFAULT = 12;

export function ChartData(props: ChartComponentProps): JSX.Element | null {
  switch (props.inputSource) {
    case "CrawlContext":
      return <ChartDataUsingCrawlContext {...props} />;
    case "GqlQuery":
      return <ChartDataUsingGqlQuery {...props} />;
    default:
      return null;
  }
}

function ChartDataUsingCrawlContext(
  props: ChartUsingCrawlContextComponentProps,
): JSX.Element {
  const category = useSearchParam("category");
  const { selectedCrawlSegment, module, crawl, crawlProject, crawlReports } =
    useCrawlContextData();

  const isMissingRequiredSource = isChartMissingRequiredSource(
    props.requiredSources,
    crawl.crawlTypes,
  );

  const isChartUnavailable = isMissingRequiredSource;
  const segmentName = selectedCrawlSegment?.segment.name;
  const totalUrls =
    crawlReports.find((report) => report.reportTemplateCode === "all_pages")
      ?.basic ?? 0;

  const relevantCrawlReports = crawlReports.filter(
    (stat) => props.reportStatFilter?.(stat, category) ?? true,
  );

  const reportTemplateCodesOrder = props.reportTemplateCodesOrder ?? [];

  const staticallyOrderedRelevantCrawlReports = reportTemplateCodesOrder
    .map((reportTemplateCode) =>
      relevantCrawlReports.find(
        (reportStat) => reportStat.reportTemplateCode === reportTemplateCode,
      ),
    )
    .filter((reportStat) => !!reportStat);

  const otherRelevantCrawlReports = relevantCrawlReports.filter(
    (reportStat) =>
      !reportTemplateCodesOrder.includes(reportStat.reportTemplateCode),
  );

  const reportStats = orderBy(
    [...staticallyOrderedRelevantCrawlReports, ...otherRelevantCrawlReports],
    props.reportStatsOrderBy?.field ?? [],
    props.reportStatsOrderBy?.direction ?? [],
  ).slice(0, props.reportStatsLimit ?? REPORT_STATS_LIMIT_DEFAULT);

  const getReportUnitCallback = React.useCallback(
    (report) => getReportUnit(report, module),
    [module],
  );

  const getAggregatedMetricCallback = React.useCallback(
    (report) => getAggregatedMetric(report, module),
    [module],
  );

  return (
    <ChartDataContext.Provider
      value={{
        inputSource: "CrawlContext",
        loading: false,
        error: undefined,
        isChartUnavailable,
        totalUrls,
        segmentName,
        crawl,
        project: crawlProject,
        reportStats,
        getReportUnit: getReportUnitCallback,
        getAggregatedMetric: getAggregatedMetricCallback,
      }}
    >
      {props.children}
    </ChartDataContext.Provider>
  );
}

function ChartDataUsingGqlQuery(
  props: ChartUsingGqlQueryComponentProps,
): JSX.Element {
  const category = useSearchParam("category");
  const crawlContext = useCrawlContextData();
  const { selectedCrawlSegment, module, crawl, crawlReports, crawlProject } =
    crawlContext;

  const isMissingRequiredSource = isChartMissingRequiredSource(
    props.requiredSources,
    crawl.crawlTypes,
  );

  const { data, error, loading } = useQuery(props.gqlDocument, {
    variables: props.gqlVariables(crawlContext, category),
    fetchPolicy: "cache-first",
    skip: isMissingRequiredSource,
  });

  const relevantCrawlReports = getReportStatsFromCustomQuery(data).filter(
    (stat) => props.reportStatFilter?.(stat, category) ?? true,
  );

  const reportTemplateCodesOrder = props.reportTemplateCodesOrder ?? [];

  const staticallyOrderedRelevantCrawlReports = reportTemplateCodesOrder
    .map((reportTemplateCode) =>
      relevantCrawlReports.find(
        (reportStat) => reportStat.reportTemplateCode === reportTemplateCode,
      ),
    )
    .filter((reportStat) => !!reportStat);

  const otherRelevantCrawlReports = relevantCrawlReports.filter(
    (reportStat) =>
      !reportTemplateCodesOrder.includes(reportStat.reportTemplateCode),
  );

  const reportStats = orderBy(
    [...staticallyOrderedRelevantCrawlReports, ...otherRelevantCrawlReports],
    props.reportStatsOrderBy?.field ?? [],
    props.reportStatsOrderBy?.direction ?? [],
  )
    .slice(0, props.reportStatsLimit ?? REPORT_STATS_LIMIT_DEFAULT)
    // Originally, the API returned 30 items. At the time of the writing,
    // the limit has been increased but the UI/UX had been designed around the 30 items limit in mind.
    // As a result, we are keeping this limit in the UI.
    .map(applyTrendLimitToReportStat);

  const isChartUnavailable = Boolean(isMissingRequiredSource || error);
  const segmentName = selectedCrawlSegment?.segment.name;
  const totalUrls =
    crawlReports.find((report) => report.reportTemplateCode === "all_pages")
      ?.basic ?? 0;

  const getReportUnitCallback = React.useCallback(
    (report) => getReportUnit(report, module),
    [module],
  );

  const getAggregatedMetricCallback = React.useCallback(
    (report) => getAggregatedMetric(report, module),
    [module],
  );

  return (
    <ChartDataContext.Provider
      value={{
        inputSource: "GqlQuery",
        loading,
        error,
        isChartUnavailable,
        totalUrls,
        segmentName,
        crawl,
        project: crawlProject,
        reportStats,
        getReportUnit: getReportUnitCallback,
        getAggregatedMetric: getAggregatedMetricCallback,
      }}
    >
      {props.children}
    </ChartDataContext.Provider>
  );
}

// The intent is to create a set of guarantees to ensure
// that apollo cache will know how to store ReportStat entities,
// parsers not having to worry about inexistence of certain fields
// and for custom query configs to not to have to check existence of commonly queried fields.

const customQueryReportStatEntitySchema = Yup.object({
  crawlId: Yup.string().required(),
  segmentId: Yup.string().nullable().defined(),
  reportTemplateCode: Yup.string().required(),
  reportTemplateName: Yup.string().required(),
});

const singleReportStatQuerySchema = Yup.object({
  getReportStat: customQueryReportStatEntitySchema.required(),
});

const multipleReportStatsQuerySchema = Yup.object({
  getReportStats: Yup.array(customQueryReportStatEntitySchema).required(),
});

const reportStatsFromCrawlQuerySchema = Yup.object({
  getCrawl: Yup.object({
    id: Yup.string().required(),
    reportStats: Yup.array(customQueryReportStatEntitySchema).required(),
  }).required(),
});

function getReportStatsFromCustomQuery(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: Record<string, any>,
): ChartConfigGenericReportStat[] {
  try {
    if (singleReportStatQuerySchema.isValidSync(data)) {
      return [data.getReportStat];
    } else if (multipleReportStatsQuerySchema.isValidSync(data)) {
      return data.getReportStats;
    } else if (reportStatsFromCrawlQuerySchema.isValidSync(data)) {
      return data.getCrawl.reportStats;
    }
  } catch (e) {
    console.error(e);
    return [];
  }

  return [];
}

function applyTrendLimitToReportStat(
  report: ChartConfigGenericReportStat,
): ChartConfigGenericReportStat {
  return {
    ...report,
    trend: report.trend?.slice(0, REPORT_STAT_TREND_ITEM_LIMIT),
  };
}
