import { createSvgIcon } from "@lumar/shared";

export const PriorityHighestIcon = createSvgIcon(
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 10.606L4.84322 11.4167L10.0079 6.59753L10.5587 7.11159L10.5557 7.10876L15.1441 11.3907L16 10.5919C14.7318 9.40846 11.1913 6.10437 10.0079 5C9.12833 5.82023 9.98547 5.02034 4 10.606Z"
      fill="#DC2626"
    />
    <path
      d="M4 10.606L4.84322 11.4167L10.0079 6.59753L10.5587 7.11159L10.5557 7.10876L15.1441 11.3907L16 10.5919C14.7318 9.40846 11.1913 6.10437 10.0079 5C9.12833 5.82023 9.98547 5.02034 4 10.606"
      stroke="#DC2626"
      strokeWidth="0.5"
      fill="none"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 15.1895L4.82553 16.0002L9.88183 11.181L10.4211 11.6951L10.4182 11.6923L14.9103 15.9742L15.7483 15.1754C14.5067 13.992 11.0404 10.6879 9.88183 9.5835C9.02074 10.4037 9.8599 9.60383 4 15.1895Z"
      fill="#DC2626"
    />
    <path
      d="M4 15.1895L4.82553 16.0002L9.88183 11.181L10.4211 11.6951L10.4182 11.6923L14.9103 15.9742L15.7483 15.1754C14.5067 13.992 11.0404 10.6879 9.88183 9.5835C9.02074 10.4037 9.8599 9.60383 4 15.1895"
      stroke="#DC2626"
      strokeWidth="0.5"
      fill="none"
    />
  </>,
  "PriorityHighestIcon",
  { viewBox: "0 0 20 20" },
);
