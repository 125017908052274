import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { SegmentRow } from "../SegmentsGrid";
import { alpha, makeStyles, useTheme } from "@material-ui/core";
import { HighchartsChart } from "../../../_common/charts/components/HighchartsChart";
import { Typography, useTranslation } from "@lumar/shared";
import { Link, useParams } from "react-router-dom";
import { Routes } from "../../../_common/routing/routes";
import { CrawlStatus } from "../../../graphql";

const useStyles = makeStyles(() => ({
  link: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "inherit",
    color: "inherit",
  },
}));

interface Props extends GridRenderCellParams {
  crawlInProgress: boolean;
  crawlStatus: CrawlStatus | undefined;
}

export function TrendCell({
  row: rowParam,
  crawlInProgress,
  crawlStatus,
}: Props): JSX.Element {
  const row = rowParam as SegmentRow;

  const classes = useStyles();
  const { t } = useTranslation(["common", "segments"]);
  const { accountId, projectId, crawlId } = useParams<{
    accountId: string;
    projectId: string;
    crawlId: string;
  }>();

  if (row.isGroup) {
    return <></>;
  }

  if (row.isGenerating) {
    return (
      <Typography variant="subtitle3Medium">
        {t("segments:generating")}
      </Typography>
    );
  }

  if (crawlInProgress) {
    const statusKey = crawlStatus?.toLowerCase() as
      | Lowercase<keyof typeof CrawlStatus>
      | undefined;
    const crawlStatusName = statusKey
      ? `${t(`common:crawlStatus.${statusKey}`)}...`
      : "-";

    return <Typography variant="subtitle3Medium">{crawlStatusName}</Typography>;
  }

  // eslint-disable-next-line fp/no-mutating-methods
  const sortedTrend = (row.report?.reportTrend || [])
    .map((x) => ({ ...x, createdAt: new Date(x.createdAt || "0") }))
    .sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());

  return (
    <Link
      to={Routes.Report.getUrl({
        accountId,
        projectId,
        crawlId,
        reportTemplateCodeWithTypeCode: "all_pages_basic",
        segmentId: row.id,
      })}
      className={classes.link}
    >
      <TrendChart
        data={
          sortedTrend.map((trend) => ({
            x: trend.createdAt.getTime(),
            y: trend.basic ?? 0,
          })) || []
        }
      />
    </Link>
  );
}

function TrendChart({
  data: dataParam,
}: {
  data: { x: number; y: number }[];
}): JSX.Element {
  const theme = useTheme();

  const data =
    dataParam.length === 1
      ? [dataParam[0], { x: dataParam[0].x + 1, y: dataParam[0].y }]
      : dataParam;

  const options: Highcharts.Options = {
    chart: {
      type: "area",
      animation: false,
      width: 155,
      height: 32,
      backgroundColor: "transparent",
      margin: [0, 0, 0, 0],
    },
    colors: [theme.palette.grey[800]],
    plotOptions: {
      series: {
        lineWidth: 1,
      },
      area: {
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, theme.palette.grey[200]],
            [1, alpha(theme.palette.grey[200], 0.3)],
          ],
        },
        marker: {
          enabled: false,
        },
      },
    },
    series: [
      {
        fillOpacity: 0.2,
        data,
        enableMouseTracking: false,
        animation: false,
        type: "area",
      },
    ],
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
      min: 0,
    },
    legend: {
      enabled: false,
    },
  };

  return <HighchartsChart options={options} />;
}
