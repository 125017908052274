import {
  AccessibilityLumar,
  categoryIconConfig,
  DocumentReportSolid,
  Done,
  isNestedNavigationMenuChildItemGroup,
  isNestedNavigationMenuChildItemSelected,
  MagnifyingGlass,
  NestedNavigationMenu,
  NestedNavigationMenuChildItem,
  NestedNavigationMenuParentItem,
  NestedNavigationMenuParentItemBooleanProperty,
  NestedNavigationMenuSection,
  useTranslation,
  ApolloError,
  useSession,
  CustomReports,
  Chip,
} from "@lumar/shared";
import { CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  CrawlReportCategoryTreeNode,
  useCrawlContext,
} from "../../crawl-overview/CrawlContext";
import { Routes } from "../../_common/routing/routes";
import { useParams, useRouteMatch } from "react-router-dom";
import { History } from "history";
import { ProjectTasksCountChip } from "./ProjectTasksCountChip";
import { insertIf } from "../../_common/insertIf";
import { isNonNullable } from "../../_common/isNonNullable";
import { getReportCodes } from "../../report/helpers/getReportCodes";
import { ModuleCode } from "../../graphql";

export function CrawlSidebarMenu(): JSX.Element {
  const { t } = useTranslation("common");
  const { sections, loading, error } = useCrawlSidebarMenuSections();

  if (error) {
    return (
      <div style={{ marginTop: 8 }}>
        <Alert severity="error">{t("genericError")}</Alert>
      </div>
    );
  }

  if (loading) {
    return (
      <CircularProgress
        size="2rem"
        style={{ display: "block", margin: "auto", marginTop: "64px" }}
      />
    );
  }

  return <NestedNavigationMenu sections={sections} />;
}

function useCrawlSidebarMenuSections(): {
  sections: NestedNavigationMenuSection[];
  loading: boolean;
  error?: ApolloError;
} {
  const { t } = useTranslation("sidebar");
  const { isDeepCrawlAdmin, hasFeatureFlagEnabled } = useSession();
  const { data, errors, loading, crawlNotFound } = useCrawlContext();
  const { accountId, projectId, crawlId } = useParams<{
    accountId: string;
    projectId: string;
    crawlId: string;
  }>();

  const reportRouteMatch = useRouteMatch<{
    reportTemplateCodeWithTypeCode: string;
  }>(Routes.Report.ROUTE);

  if (loading) {
    return {
      sections: [],
      loading: true,
    };
  }

  if (errors) {
    return {
      sections: [],
      loading: false,
      error: errors[0],
    };
  }

  if (crawlNotFound) {
    return {
      sections: [],
      loading: false,
    };
  }

  function getCrawlReportCategoryHref(
    crawlReportCategory: CrawlReportCategoryTreeNode,
  ): string {
    return Routes.CrawlOverview.getUrl({
      accountId: accountId,
      crawlId: crawlId,
      projectId: projectId,
      segmentId: data?.selectedCrawlSegment?.segment.id,
      type: "dashboard",
      category: crawlReportCategory.code,
    });
  }

  const adminCrawlReportCategory = data?.crawlReportCategoriesTree.find(
    (crawlReportCategory) => crawlReportCategory.code === "admin",
  );
  const customCrawlReportCategory = data?.crawlReportCategoriesTree.find(
    (crawlReportCategory) => crawlReportCategory.code === "custom",
  );
  const summaryCrawlReportCategory = data?.crawlReportCategoriesTree.find(
    (crawlReportCategory) => crawlReportCategory.code === "summary",
  );

  const topSection = {
    id: "top-section",
    items: [
      {
        id: "project-tasks",
        name: t("projectTasks"),
        href: Routes.Tasks.getUrl({ accountId, crawlId, projectId }),
        selected: isMenuItemSelected,
        adornment: ProjectTasksCountChip,
        icon: Done,
      },

      summaryCrawlReportCategory
        ? mapOverviewCrawlReportCategoryToNestedNavigationParentMenuItem(
            summaryCrawlReportCategory,
            getCrawlReportCategoryHref,
            getCrawlReportCategorySelectedBooleanProperty(
              reportRouteMatch?.params.reportTemplateCodeWithTypeCode,
            ),
          )
        : null,
      {
        id: "data-explorer",
        name: t("dataExplorer"),
        href: Routes.DataExplorer.getUrl({
          accountId,
          crawlId,
          projectId,
        }),
        icon: MagnifyingGlass,
        selected: isMenuItemSelected,
      },
      {
        id: "all-pages",
        name: t("allPages"),
        href: Routes.Report.getUrl({
          accountId,
          crawlId,
          projectId,
          reportTemplateCode: "all_pages",
          reportTypeCode: "basic",
        }),
        icon: DocumentReportSolid,
        selected: isMenuItemSelected,
      },

      ...insertIf(data?.moduleCode === ModuleCode.Accessibility, {
        id: "a11y-instances",
        name: t("a11yInstances"),
        href: Routes.Report.getUrl({
          accountId,
          crawlId,
          projectId,
          reportTemplateCode: "accessibility_issue_instances",
          reportTypeCode: "basic",
        }),
        icon: AccessibilityLumar,
        selected: isMenuItemSelected,
      }),
      customCrawlReportCategory
        ? mapCrawlReportCategoryToNestedNavigationParentMenuItem(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            customCrawlReportCategory!,
            getCrawlReportCategoryHref,
            getCrawlReportCategorySelectedBooleanProperty(
              reportRouteMatch?.params.reportTemplateCodeWithTypeCode,
            ),
          )
        : null,
      ...insertIf(hasFeatureFlagEnabled("custom-reports"), {
        id: "custom-reports",
        name: t("customReports"),
        href: Routes.CustomReports.getUrl({
          accountId,
          projectId,
          crawlId,
          segmentId: data?.selectedCrawlSegment?.segment.id,
        }),
        icon: CustomReports,
        selected: isMenuItemSelected,
        adornment: () => <Chip label="NEW" color="primary" />,
      }),
      adminCrawlReportCategory && isDeepCrawlAdmin
        ? mapCrawlReportCategoryToNestedNavigationParentMenuItem(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            adminCrawlReportCategory!,
            getCrawlReportCategoryHref,
            getCrawlReportCategorySelectedBooleanProperty(
              reportRouteMatch?.params.reportTemplateCodeWithTypeCode,
            ),
          )
        : null,
    ].filter(isNonNullable),
  };

  const otherModulesSections = (data?.crawlReportCategoriesTree ?? [])
    .filter(
      (c) => c.code !== "admin" && c.code !== "custom" && c.code !== "summary",
    )
    .map((reportCategory) => {
      return {
        showDivider: true,
        id: reportCategory.code,
        items: [
          mapOverviewCrawlReportCategoryToNestedNavigationParentMenuItem(
            reportCategory,
            getCrawlReportCategoryHref,
            getCrawlReportCategorySelectedBooleanProperty(
              reportRouteMatch?.params.reportTemplateCodeWithTypeCode,
            ),
          ),
          ...reportCategory.nodes.map((childReportCategory) =>
            mapCrawlReportCategoryToNestedNavigationParentMenuItem(
              childReportCategory,
              getCrawlReportCategoryHref,
              getCrawlReportCategorySelectedBooleanProperty(
                reportRouteMatch?.params.reportTemplateCodeWithTypeCode,
              ),
            ),
          ),
        ],
      };
    });

  return {
    loading: false,
    sections: [topSection, ...otherModulesSections],
  };
}

function getCrawlReportCategorySelectedBooleanProperty(
  reportTemplateCodeWithTypeCode?: string,
): (
  crawlReportCategory: CrawlReportCategoryTreeNode,
) => typeof isReportCategorySelected | boolean {
  return (crawlReportCategory: CrawlReportCategoryTreeNode) => {
    if (reportTemplateCodeWithTypeCode) {
      const { reportTemplateCode } = getReportCodes(
        reportTemplateCodeWithTypeCode,
      );

      return isReportCategorySelectedInReportView(
        crawlReportCategory,
        reportTemplateCode,
      );
    }
    return isReportCategorySelected;
  };
}

function mapOverviewCrawlReportCategoryToNestedNavigationParentMenuItem(
  crawlReportCategory: CrawlReportCategoryTreeNode,
  getHref: (crawlReportCategory: CrawlReportCategoryTreeNode) => string,
  crawlReportCategorySelected: (
    crawlReportCategory: CrawlReportCategoryTreeNode,
  ) => NestedNavigationMenuParentItemBooleanProperty,
): NestedNavigationMenuParentItem {
  return {
    id: crawlReportCategory.code,
    name: crawlReportCategory.name + " Overview",
    icon:
      categoryIconConfig.get(crawlReportCategory.code) || DocumentReportSolid,
    open: isParentReportCategoryOpen,
    selected: crawlReportCategorySelected(crawlReportCategory),
    href: getHref(crawlReportCategory),
  };
}

function mapCrawlReportCategoryToNestedNavigationParentMenuItem(
  crawlReportCategory: CrawlReportCategoryTreeNode,
  getHref: (crawlReportCategory: CrawlReportCategoryTreeNode) => string,
  crawlReportCategorySelected: (
    crawlReportCategory: CrawlReportCategoryTreeNode,
  ) => typeof isReportCategorySelected | boolean,
): NestedNavigationMenuParentItem {
  return {
    id: crawlReportCategory.code,
    name: crawlReportCategory.name,
    icon:
      categoryIconConfig.get(crawlReportCategory.code) || DocumentReportSolid,
    open: isParentReportCategoryOpen,
    selected: crawlReportCategorySelected(crawlReportCategory),
    href: getHref(crawlReportCategory),
    children: crawlReportCategory.nodes.map((childReportCategory) => {
      return {
        id: childReportCategory.code,
        name: childReportCategory.name,
        selected: crawlReportCategorySelected(childReportCategory),
        href: getHref(childReportCategory),
      };
    }),
  };
}

function isReportCategorySelectedInReportView(
  category: CrawlReportCategoryTreeNode,
  reportTemplateCode: string,
): boolean {
  return !!category.reports.find(
    (report) => report.reportTemplate.code === reportTemplateCode,
  );
}

function isReportCategorySelected(
  item: NestedNavigationMenuChildItem | NestedNavigationMenuParentItem,
  history: History,
): boolean {
  const hrefUrl = new URL(item.href || "", window.location.origin);
  return (
    hrefUrl.pathname === history.location.pathname &&
    hrefUrl.searchParams.get("category") ===
      new URLSearchParams(history.location.search).get("category")
  );
}

function isParentReportCategoryOpen(
  parent: NestedNavigationMenuParentItem,
  history: History,
): boolean {
  return (
    isReportCategorySelected(parent, history) ||
    !!parent.children?.find((child) => {
      return isNestedNavigationMenuChildItemGroup(child)
        ? !!child.children.find((child) =>
            isNestedNavigationMenuChildItemSelected(child, history, parent),
          )
        : isNestedNavigationMenuChildItemSelected(child, history, parent);
    })
  );
}

function isMenuItemSelected(
  item: NestedNavigationMenuParentItem | NestedNavigationMenuChildItem,
  history: History,
): boolean {
  const hrefUrl = new URL(item.href || "", window.location.origin);
  return hrefUrl.pathname === history.location.pathname;
}
