import { GridColDef, GridColumns } from "@mui/x-data-grid-pro";
import { DateResolvedCell } from "./cells/DateResolvedCell";
import { TitleCell } from "./cells/TitleCell";
import { DescriptionCell } from "./cells/DescriptionCell";
import { AssigneeCell } from "./cells/AssigneeCell";
import { UrlCountCell } from "./cells/UrlCountCell";
import { UnresolvedPercentCell } from "./cells/UnresolvedPercentCell";
import { PriorityCell } from "./cells/PriorityCell";
import { TrendCell } from "./cells/TrendCell";
import React from "react";
import { ArchivedTask } from "../data/types";
import { makeStyles } from "@material-ui/core";
import { insertIf, useBrand, useTranslation } from "@lumar/shared";

export function useArchivedTasksColumns({
  onTaskSelected,
}: {
  onTaskSelected: (task: ArchivedTask) => void;
}): GridColumns {
  const { t } = useTranslation("taskManager");
  const brand = useBrand();

  return [
    {
      field: "fixedAt",
      headerName: t("archivedTasks.headerFixedAt"),
      flex: 2,
      minWidth: 100,
      sortable: true,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Wrapper onClick={() => onTaskSelected(params.row as ArchivedTask)}>
          <DateResolvedCell {...params} />
        </Wrapper>
      ),
    },
    {
      field: "title",
      headerName: t("archivedTasks.headerTitle"),
      flex: 4,
      minWidth: 100,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Wrapper onClick={() => onTaskSelected(params.row as ArchivedTask)}>
          <TitleCell {...params} />
        </Wrapper>
      ),
    },
    {
      field: "description",
      headerName: t("archivedTasks.headerDescription"),
      flex: 4,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Wrapper onClick={() => onTaskSelected(params.row as ArchivedTask)}>
          <DescriptionCell {...params} />
        </Wrapper>
      ),
    },
    ...insertIf<GridColDef>(
      brand.featureAvailability.taskManagerEmailAssignment,
      {
        field: "assignedTo",
        headerName: t("archivedTasks.headerAssignedTo"),
        flex: 2,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
          <Wrapper onClick={() => onTaskSelected(params.row as ArchivedTask)}>
            <AssigneeCell {...params} />
          </Wrapper>
        ),
      },
    ),
    {
      field: "remaining",
      headerName: t("archivedTasks.headerUrlCount"),
      flex: 2,
      align: "right",
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Wrapper onClick={() => onTaskSelected(params.row as ArchivedTask)}>
          <UrlCountCell {...params} />
        </Wrapper>
      ),
    },
    {
      field: "unresolvedPercent",
      headerName: t("archivedTasks.headerUnresolvedPercent"),
      flex: 2,
      align: "right",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      valueGetter: (params) =>
        Boolean(params.row.identified)
          ? Math.ceil(
              ((params.row.remaining || 0) / params.row.identified) * 100,
            )
          : 0,
      renderCell: (params) => (
        <Wrapper onClick={() => onTaskSelected(params.row as ArchivedTask)}>
          <UnresolvedPercentCell {...params} />
        </Wrapper>
      ),
    },
    {
      field: "trend",
      headerName: t("archivedTasks.headerUnresolvedTrend"),
      minWidth: 192,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Wrapper onClick={() => onTaskSelected(params.row as ArchivedTask)}>
          <TrendCell {...params} />
        </Wrapper>
      ),
    },
    {
      field: "priority",
      headerName: t("archivedTasks.headerPriority"),
      flex: 1.5,
      minWidth: 80,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Wrapper onClick={() => onTaskSelected(params.row as ArchivedTask)}>
          <PriorityCell {...params} />
        </Wrapper>
      ),
    },
  ];
}

function Wrapper({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick: () => void;
}): JSX.Element {
  const classes = useStyles();

  return (
    <button onClick={onClick} className={classes.button}>
      {children}
    </button>
  );
}

const useStyles = makeStyles((theme) => ({
  button: {
    borderStyle: "none",
    background: "none",
    width: "100%",
    height: "100%",
    textAlign: "inherit",
    cursor: "pointer",
    padding: theme.spacing(0, 1, 0, 2),
    fontFamily: "inherit",
  },
}));
