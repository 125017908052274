import { useParams } from "react-router-dom";
import {
  LegacyTaskOrderField,
  OrderDirection,
  useProjectArchivedTasksQuery,
} from "../../graphql";
import {
  ApolloError,
  BlueGridPaginationState,
  useUrlServerPagination,
} from "@lumar/shared";
import { ArchivedTask } from "./types";

type ProjectArchivedTasks = {
  loading: boolean;
  error?: string;
  tasks: ArchivedTask[];
  taskCount: number;
  totalTaskCount: number;
  pagination: {
    paginationState: BlueGridPaginationState;
    onPaginationStateChange: (state: BlueGridPaginationState) => void;
  };
};

export function useProjectArchivedTasks({
  search,
}: {
  search: string;
}): ProjectArchivedTasks {
  const { projectId } = useParams<{ projectId: string }>();

  const { pageInfo, ...pagination } = useUrlServerPagination(
    10,
    LegacyTaskOrderField.FixedAt,
    OrderDirection.Desc,
  );

  const { loading, error, data } = useProjectArchivedTasksQuery({
    variables: {
      projectId,
      cursor: pageInfo.after,
      filter: {
        fixedAt: { isNull: false },
        ...(search ? { title: { contains: search } } : {}),
      },
      orderBy: pageInfo.orderBy,
    },
  });

  return {
    loading,
    error: getErrorMessage(error),
    tasks: data?.getProject?.legacyTasks.edges.map((x) => x.node) || [],
    taskCount: data?.getProject?.legacyTasks.totalCount || 0,
    totalTaskCount: data?.getProject?.allTasks.totalCount || 0,
    pagination,
  };
}

function getErrorMessage(error?: ApolloError): string | undefined {
  return (
    error?.graphQLErrors[0]?.message ||
    error?.clientErrors[0]?.message ||
    error?.protocolErrors[0]?.message ||
    error?.networkError?.message
  );
}
