import React, { useState, ReactNode } from "react";
import { CircularProgress, useTheme } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import LoopIcon from "@material-ui/icons/Loop";
import { useTranslation } from "react-i18next";
import {
  ApolloError,
  EmptyState,
  EmptyStateProps,
  insertIf,
  useSession,
} from "@lumar/shared";

import { useGenericParams } from "../../../routing/useGenericParams";
import { assert } from "../../../assert";
import { Routes } from "../../../routing/routes";
import {
  RoleCode,
  useProjectSummaryQuery,
  useRunNewCrawlMutation,
} from "../../../../graphql";
import { RunNewCrawlErrorDialog } from "../../../run-new-crawl-error-dialog/RunNewCrawlErrorDialog";
import { isCrawlInProgress } from "../../../../account-projects/projects-filter/helpers";

export function RunNewCrawlMessage(props: {
  type?: "noChanges" | "trend";
  message: ReactNode;
}): JSX.Element {
  const { t } = useTranslation(["common", "charts"]);
  const history = useHistory();
  const { accountId, projectId, crawlId } = useGenericParams();
  const [error, setError] = useState<ApolloError | null>(null);
  const {
    hasSufficientRole,
    account: {
      subscription: { segmentationAvailable },
    },
  } = useSession();

  const hasEditorPermission = hasSufficientRole(RoleCode.Editor);

  assert(accountId);
  assert(projectId);
  assert(crawlId);

  const { data, loading: summaryLoading } = useProjectSummaryQuery({
    variables: { id: projectId, segmentationAvailable: segmentationAvailable },
  });

  const crawlStatus = data?.project?.lastCrawlStatus;
  const isTestSuite = data?.project?.isTestSuite;
  const isInProgress = isCrawlInProgress(crawlStatus);

  const [runNewCrawl, { loading: runNewCrawlLoading }] = useRunNewCrawlMutation(
    {
      variables: { projectId },
      onCompleted() {
        history.push(
          Routes.Crawls.getUrl({
            accountId,
            projectId,
            tab: "progress",
          }),
        );
      },
      onError(error) {
        setError(error);
      },
    },
  );

  function dismissErrorDialog(): void {
    setError(null);
  }

  return (
    <div style={{ textAlign: "center" }}>
      <EmptyState
        icon={<RunNewCrawlIcon type={props.type} />}
        description={props.message}
        actions={
          hasEditorPermission
            ? [
                {
                  type: "internalLink",
                  variant: "outlined",
                  color: "default",
                  icon: <EditIcon />,
                  title: t("common:edit"),
                  to: Routes.Crawls.getUrl({
                    accountId,
                    projectId,
                    tab: "edit",
                    step: 1,
                  }),
                },
                ...insertIf<NonNullable<EmptyStateProps["actions"]>[0]>(
                  isInProgress && !summaryLoading,
                  {
                    type: "button",
                    disabled: runNewCrawlLoading,
                    onClick: () => {
                      history.push(
                        Routes.Crawls.getUrl({
                          accountId,
                          projectId,
                          tab: "progress",
                        }),
                      );
                    },
                    title: t("common:currentCrawl"),
                  },
                ),
                ...insertIf<NonNullable<EmptyStateProps["actions"]>[0]>(
                  !isInProgress && !summaryLoading && !isTestSuite,
                  {
                    type: "button",
                    disabled: runNewCrawlLoading,
                    icon: runNewCrawlLoading ? (
                      <CircularProgress size="1rem" />
                    ) : (
                      <LoopIcon />
                    ),
                    onClick: runNewCrawl,
                    title: t("common:runCrawlNow"),
                  },
                ),
              ]
            : []
        }
      />
      {error && (
        <RunNewCrawlErrorDialog
          projectId={projectId}
          crawlId={crawlId}
          error={error}
          onClose={dismissErrorDialog}
        />
      )}
    </div>
  );
}

function RunNewCrawlIcon(props: { type?: "noChanges" | "trend" }): JSX.Element {
  const { type = "trend" } = props;
  const theme = useTheme();

  switch (type) {
    case "noChanges":
      return (
        <svg
          width="38"
          height="38"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.5 2C13.0523 2 13.5 2.44772 13.5 3V4.27924L18.5 5.94591L21.1838 5.05132C21.7077 4.87667 22.274 5.15983 22.4487 5.68377C22.6233 6.20772 22.3402 6.77404 21.8162 6.94868L19.7649 7.63245L22.4326 15.6354C22.5925 16.043 22.4694 16.5231 22.1023 16.7992C19.9738 18.4003 17.0274 18.4003 14.8989 16.7991C14.5553 16.5407 14.4154 16.0916 14.5513 15.6838L17.2351 7.63246L13.5 6.38743V20H15.5C16.0523 20 16.5 20.4477 16.5 21C16.5 21.5523 16.0523 22 15.5 22H9.5C8.94771 22 8.5 21.5523 8.5 21C8.5 20.4477 8.94771 20 9.5 20H11.5V6.38743L7.76491 7.63245L10.4326 15.6354C10.5925 16.0431 10.4693 16.5231 10.1023 16.7992C7.97378 18.4003 5.02739 18.4003 2.89885 16.7991C2.5553 16.5407 2.41537 16.0916 2.55131 15.6838L5.23509 7.63246L3.18377 6.94868C2.65983 6.77404 2.37667 6.20772 2.55131 5.68377C2.72596 5.15983 3.29228 4.87667 3.81622 5.05132L6.5 5.94591L11.5 4.27924V3C11.5 2.44772 11.9477 2 12.5 2ZM6.50001 10.1623L4.69692 15.5715C5.82715 16.1427 7.17296 16.1429 8.3033 15.572L6.50001 10.1623ZM18.5 10.1623L16.6969 15.5715C17.8271 16.1427 19.173 16.1429 20.3033 15.572L18.5 10.1623Z"
            fill={theme.palette.primary.main}
          />
        </svg>
      );
    case "trend":
      return (
        <svg
          width="38"
          height="38"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.5 8C12.9477 8 12.5 7.55228 12.5 7C12.5 6.44772 12.9477 6 13.5 6H21.5C22.0523 6 22.5 6.44772 22.5 7V15C22.5 15.5523 22.0523 16 21.5 16C20.9477 16 20.5 15.5523 20.5 15V9.41421L14.2071 15.7071C13.8166 16.0976 13.1834 16.0976 12.7929 15.7071L9.5 12.4142L4.20711 17.7071C3.81658 18.0976 3.18342 18.0976 2.79289 17.7071C2.40237 17.3166 2.40237 16.6834 2.79289 16.2929L8.79289 10.2929C9.18342 9.90237 9.81658 9.90237 10.2071 10.2929L13.5 13.5858L19.0858 8H13.5Z"
            fill={theme.palette.primary.main}
          />
        </svg>
      );
  }
}
