import { useParams } from "react-router-dom";
import { TileChartPanelTitle } from "./TileChartPanelTitle";
import { TileChartConfigItem } from "../../../../../_common/charts/types/ChartConfig";
import { useChartDataContext } from "../../../../../_common/charts/components/chart-components/ChartDataContext";
import { SmallColumnChart } from "../../../../../_common/charts/small-column-chart/SmallColumnChart";
import { ChartPanelErrorMessage } from "../../../../../_common/charts/components/chart-messages/ChartPanelErrorMessage";
import { useTranslation } from "@lumar/shared";
import { ChartConfigReportStatArray } from "../../../../../_common/charts/types/ChartConfigItemBase";
import { getIconForChart } from "../getIconForChart";

export function TileChartComponent(props: TileChartConfigItem): JSX.Element {
  const { t } = useTranslation("charts");
  const { accountId, projectId, crawlId } = useParams<{
    accountId: string;
    projectId: string;
    crawlId: string;
  }>();

  const { reportStats, error } = useChartDataContext();

  if (error) {
    return (
      <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
        <ChartPanelErrorMessage>
          {t("errors.queryError")}
        </ChartPanelErrorMessage>
      </div>
    );
  }

  return (
    <>
      <TileChartPanelTitle
        title={props.title(reportStats as ChartConfigReportStatArray)}
        description={props.description?.(
          { accountId, crawlId, projectId },
          reportStats as ChartConfigReportStatArray,
        )}
        descriptionTitle={props.descriptionTitle?.(
          reportStats as ChartConfigReportStatArray,
        )}
        unitDescription={props.unitDescription}
        icon={getIconForChart(props.icon)}
        testAttributePrefix={props.testAttributePrefix}
        link={props.link?.(accountId, projectId, crawlId)}
      />
      <SmallColumnChart {...props} />
    </>
  );
}
