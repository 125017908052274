import { createSvgIcon } from "@lumar/shared";

export const PriorityLowestIcon = createSvgIcon(
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 9.39396L4.84322 8.58333L10.0079 13.4025L10.5587 12.8884L10.5557 12.8912L15.1441 8.60932L16 9.40808C14.7318 10.5915 11.1913 13.8956 10.0079 15C9.12833 14.1798 9.98547 14.9797 4 9.39396Z"
      fill="#3B82F6"
    />
    <path
      d="M4 9.39396L4.84322 8.58333L10.0079 13.4025L10.5587 12.8884L10.5557 12.8912L15.1441 8.60932L16 9.40808C14.7318 10.5915 11.1913 13.8956 10.0079 15C9.12833 14.1798 9.98547 14.9797 4 9.39396"
      stroke="#3B82F6"
      strokeWidth="0.5"
      fill="none"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 4.81046L4.82553 3.99984L9.88183 8.81897L10.4211 8.30492L10.4182 8.30774L14.9103 4.02582L15.7483 4.82459C14.5067 6.00805 11.0404 9.31213 9.88183 10.4165C9.02074 9.59627 9.8599 10.3962 4 4.81046Z"
      fill="#3B82F6"
    />
    <path
      d="M4 4.81046L4.82553 3.99984L9.88183 8.81897L10.4211 8.30492L10.4182 8.30774L14.9103 4.02582L15.7483 4.82459C14.5067 6.00805 11.0404 9.31213 9.88183 10.4165C9.02074 9.59627 9.8599 10.3962 4 4.81046"
      stroke="#3B82F6"
      strokeWidth="0.5"
      fill="none"
    />
  </>,
  "PriorityLowestIcon",
  { viewBox: "0 0 20 20" },
);
