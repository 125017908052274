import React from "react";
import { makeStyles } from "@material-ui/core";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { LegacyTaskPriority } from "../../../graphql";
import { PriorityLowestIcon } from "../../icons/PriorityLowestIcon";
import { PriorityLowIcon } from "../../icons/PriorityLowIcon";
import { PriorityMediumIcon } from "../../icons/PriorityMediumIcon";
import { PriorityHighIcon } from "../../icons/PriorityHighIcon";
import { PriorityHighestIcon } from "../../icons/PriorityHighestIcon";

export function PriorityCell(params: GridRenderCellParams): JSX.Element {
  const classes = useStyles();
  const priority = params.row.priority as LegacyTaskPriority;

  return (
    <div className={classes.container}>
      <PriorityIcon
        priority={priority}
        className={classes.icon}
        data-testid="tasks-priority-icon"
      />
    </div>
  );
}

function PriorityIcon({
  priority,
  ...props
}: {
  priority: LegacyTaskPriority;
  className?: string;
}): JSX.Element | null {
  switch (priority) {
    case LegacyTaskPriority.Note:
      return <PriorityLowestIcon {...props} />;
    case LegacyTaskPriority.Low:
      return <PriorityLowIcon {...props} />;
    case LegacyTaskPriority.Medium:
      return <PriorityMediumIcon {...props} />;
    case LegacyTaskPriority.High:
      return <PriorityHighIcon {...props} />;
    case LegacyTaskPriority.Critical:
      return <PriorityHighestIcon {...props} />;
    default:
      return null;
  }
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    fontSize: theme.typography.pxToRem(20),
  },
  badge: {
    display: "inline-block",
    borderRadius: "4px",
    fontSize: "11px",
    width: "53px",
    lineHeight: "24px",
    background: theme.palette.grey[300],
  },
  medium: {
    background: theme.palette.yellow[400],
  },
  high: {
    background: theme.palette.orange[500],
    color: "white",
  },
  critical: {
    background: theme.palette.red[500],
    color: "white",
  },
}));
