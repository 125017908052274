import {
  BlueDataGrid,
  insertIf,
  useSession,
  useTranslation,
} from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { GridColDef } from "@mui/x-data-grid";
import { GridRowData } from "@mui/x-data-grid-pro";
import React, { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { getReportImpact } from "../../_common/report-helpers/reportImpact";
import { ImpactBadge } from "../../_common/report-helpers/ReportImpactBadge";
import { getReportPriority } from "../../_common/report-helpers/reportPriority";
import { PriorityBadge } from "../../_common/report-helpers/ReportPriorityBadge";
import { Routes } from "../../_common/routing/routes";
import { RoleCode, useCustomReportTemplatesManagerQuery } from "../../graphql";
import {
  mapFloatToTotalWeight,
  mapIntToTotalSign,
} from "../_common/CustomReportHelpers";
import { CustomReportTemplateActions } from "./CustomReportTemplateActions";
import { CustomReportTemplateManagerHeader } from "./CustomReportTemplateManagerHeader";
import { CustomReportTemplateSearch } from "./CustomReportTemplateSearch";

const useStyles = makeStyles((theme) => ({
  linkCell: {
    width: "100%",
    height: "100%",
    color: theme.palette.grey[600],
    textDecoration: "none",
    display: "inline-block",
  },
}));

export const CustomReportTemplateManager = (): JSX.Element => {
  const { projectId, accountId } = useParams<{
    projectId: string;
    accountId: string;
  }>();

  const [search, setSearch] = React.useState("");
  const { hasSufficientRole } = useSession();
  const { t } = useTranslation("customReports");
  const classes = useStyles();

  const { data, loading, error } = useCustomReportTemplatesManagerQuery({
    variables: {
      projectId: projectId,
    },
  });

  const hasEditPermission = hasSufficientRole(RoleCode.Editor);
  const lastFinishedCrawl = data?.getProject?.lastFinishedCrawl;

  const rows: GridRowData[] = useMemo(() => {
    const generatedReports =
      data?.getProject?.lastFinishedCrawl?.customReports.map(
        (report) => report.customReportTemplate.id,
      ) ?? [];

    return (
      data?.getProject?.customReportTemplates.nodes
        .filter((node) =>
          node.name.toLowerCase().includes(search.toLowerCase()),
        )
        .map((node) => ({
          id: node.id,
          rawID: node.rawID,
          name: node.name,
          description: node.description,
          totalSign: node.totalSign,
          totalWeight: node.totalWeight,
          filter: node.filter,
          orderBy: node.orderBy,
          metricsGroupings: node.metricsGroupings,
          reportsGenerated: generatedReports.includes(node.id),
          reportTemplateCode: node.reportTemplateCode,
        })) ?? []
    );
  }, [data, search]);

  const columns: GridColDef[] = useMemo(
    () =>
      lastFinishedCrawl
        ? [
            {
              field: "name",
              headerName: t("customReportsManager.name"),
              flex: 1,
              renderCell: (data) => {
                if (data.row.reportsGenerated) {
                  return (
                    <Link
                      to={Routes.CustomReport.getUrl({
                        accountId,
                        projectId,
                        crawlId: lastFinishedCrawl.rawID,
                        customReportTemplateId: data.row.rawID,
                      })}
                      tabIndex={-1}
                      className={classes.linkCell}
                    >
                      {data.row.name}
                    </Link>
                  );
                } else {
                  return (
                    <Link
                      to={Routes.Report.getUrl({
                        accountId,
                        projectId,
                        crawlId: lastFinishedCrawl.rawID,
                        reportTemplateCode: data.row.reportTemplateCode,
                        reportTypeCode: "basic",
                        filter: parseFilter(data.row.filter),
                      })}
                      tabIndex={-1}
                      className={classes.linkCell}
                    >
                      {data.row.name}
                    </Link>
                  );
                }
              },
            },
            {
              field: "description",
              headerName: t("customReportsManager.description"),
              flex: 2,
              renderCell: (data) => {
                if (data.row.reportsGenerated) {
                  return (
                    <Link
                      to={Routes.CustomReport.getUrl({
                        accountId,
                        projectId,
                        crawlId: lastFinishedCrawl.rawID,
                        customReportTemplateId: data.row.rawID,
                      })}
                      tabIndex={-1}
                      className={classes.linkCell}
                    >
                      {data.row.description}
                    </Link>
                  );
                } else {
                  return (
                    <Link
                      to={Routes.Report.getUrl({
                        accountId,
                        projectId,
                        crawlId: lastFinishedCrawl.rawID,
                        reportTemplateCode: data.row.reportTemplateCode,
                        reportTypeCode: "basic",
                        filter: parseFilter(data.row.filter),
                      })}
                      tabIndex={-1}
                      className={classes.linkCell}
                    >
                      {data.row.description}
                    </Link>
                  );
                }
              },
            },
            {
              field: "status",
              headerName: t("customReportsManager.status"),
              flex: 1,
              disableColumnMenu: true,
              sortable: false,
              renderCell: (data) => {
                if (data.row.reportsGenerated) {
                  return (
                    <span>{t("customReportsManager.statusGenerated")}</span>
                  );
                } else {
                  return (
                    <span>
                      {t("customReportsManager.statusPendingNextCrawl")}
                    </span>
                  );
                }
              },
            },
            {
              field: "impact",
              headerName: t("customReportsManager.impact"),
              align: "center",
              width: 120,
              valueGetter: (data) => data.row.totalSign,
              renderCell: (data) => (
                <ImpactBadge impact={getReportImpact(data.row.totalSign)} />
              ),
            },
            {
              field: "priority",
              headerName: t("customReportsManager.priority"),
              align: "center",
              width: 120,
              valueGetter: (data) => data.row.totalWeight,
              renderCell: (data) => (
                <PriorityBadge
                  priority={getReportPriority(data.row.totalWeight)}
                />
              ),
            },

            ...insertIf<GridColDef>(hasEditPermission, {
              field: "action",
              headerName: t("customReportsManager.actions"),
              sortable: false,
              disableColumnMenu: true,
              width: 100,
              align: "center",
              headerAlign: "center",
              renderCell: (data) => (
                <CustomReportTemplateActions
                  accountId={accountId}
                  projectId={projectId}
                  crawlId={lastFinishedCrawl.rawID}
                  crawlIsArchived={Boolean(lastFinishedCrawl.archivedAt)}
                  hasGeneratedReport={data.row.reportsGenerated}
                  templateDefaultValues={{
                    customReportTemplateId: data.row.rawID,
                    name: data.row.name,
                    description: data.row.description,
                    totalSign: mapIntToTotalSign(data.row.totalSign),
                    totalWeight: mapFloatToTotalWeight(data.row.totalWeight),
                  }}
                />
              ),
            }),
          ]
        : [],
    [lastFinishedCrawl, hasEditPermission, accountId, projectId, t, classes],
  );

  return (
    <div>
      <CustomReportTemplateManagerHeader />
      <BlueDataGrid
        loading={loading}
        error={error}
        totalRowCount={Math.max(
          rows.length,
          data?.getProject?.customReportTemplates.nodes.length ?? 0,
        )}
        components={{
          ToolbarLeft: CustomReportTemplateSearch,
        }}
        componentsProps={{
          toolbarLeft: {
            search,
            setSearch,
          },
        }}
        rows={rows}
        columns={columns}
        autoRowHeight
        pagination={false}
      />
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseFilter = (filter: any): any => {
  if (filter._and.length) {
    return filter._and[0];
  } else if (filter._or) {
    return filter._or;
  } else {
    return { _or: [] };
  }
};
