import {
  InformationCircleOutlined,
  TabSpinner,
  useDateFormatter,
  useNumberFormatter,
  useTranslation,
} from "@lumar/shared";
import { Box, makeStyles, Tab, Tabs, Tooltip } from "@material-ui/core";
import { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useURLSearchParams } from "../../../../_common/routing/useURLSearchParams";
import { ReportTypeCode } from "../../../../graphql";
import { ReportInput } from "../../../Report.types";
import { ReportData, ReportDiffs } from "../rows/ReportGridRows.types";
import { mapStringToReportTypeCode } from "../../../../custom-report/_common/CustomReportHelpers";
import { useCrawlContextData } from "../../../../crawl-overview/CrawlContext";

interface RouteParams {
  accountId: string;
  projectId: string;
  crawlId: string;
  reportTemplateCodeWithTypeCode: string;
}

const useStyles = makeStyles((theme) => ({
  conatainer: {
    marginBottom: theme.spacing(2),
    display: "flex",
  },
  tabs: { flexGrow: 1, height: 47 },
  label: { display: "flex", flexDirection: "row", alignItems: "center" },
  root: {
    height: 47,
    padding: "6px 20px",
    color: theme.palette.grey[900],
    "&:hover": { color: theme.palette.primary.main },
  },
  totalRows: { fontSize: 12, marginLeft: 4 },
  icon: {
    color: theme.palette.grey[400],
    fontSize: theme.typography.pxToRem(22),
    margin: theme.spacing(0, 0.375, 0.5, 0.5),
  },
  content: {
    flexShrink: 0,
    display: "flex",
    alignItems: "end",
    justifyContent: "end",
    paddingBottom: theme.spacing(0.875),
    borderBottomColor: theme.palette.grey[300],
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
  },
}));

export type ReportGridTabsMode = "visible" | "hidden" | "onlyTotalRowsVisible";

export function ReportGridTabs(props: {
  mode: ReportGridTabsMode;
  diffs: ReportDiffs;
  reportInput: ReportInput;
  totalRows?: number;
  loading?: boolean;
  children?: React.ReactNode;
  onTabChange?: (value: string) => void;
}): JSX.Element | null {
  const searchParams = useURLSearchParams();
  const classes = useStyles();
  const { t } = useTranslation("report");
  const formatter = useNumberFormatter();
  const dateFormatter = useDateFormatter();
  const params = useParams<RouteParams>();
  const {
    crawl: { comparedTo },
  } = useCrawlContextData();

  const [tab, setTab] = useState(
    props.reportInput.reportTypeCode.toLowerCase(),
  );

  useEffect(
    () => setTab(props.reportInput.reportTypeCode.toLowerCase()),
    [props.reportInput.reportTypeCode, params.reportTemplateCodeWithTypeCode],
  );

  const onChange = (_: ChangeEvent<unknown>, value: string): void => {
    setTimeout(() => setTab(value));
    props.onTabChange?.(value);
  };

  const date = new Date(comparedTo?.createdAt ?? new Date());
  function getDiffReport(code: ReportTypeCode): {
    label?: string;
    description?: string;
    position: number;
  } {
    switch (code) {
      case ReportTypeCode.Basic:
        return {
          label: t("reportGridTabsLabels.all"),
          position: 1,
        };
      case ReportTypeCode.Added:
        return {
          label: t("reportGridTabsLabels.added"),
          description: t("reportGridTabsLabels.addedTooltipText", {
            date: dateFormatter(date, {
              dateStyle: "medium",
              timeStyle: "short",
            }),
          }),
          position: 2,
        };
      case ReportTypeCode.Removed:
        return {
          label: t("reportGridTabsLabels.removed"),
          description: t("reportGridTabsLabels.removeTooltipText", {
            date: dateFormatter(date, {
              dateStyle: "medium",
              timeStyle: "short",
            }),
          }),
          position: 3,
        };
      case ReportTypeCode.Missing:
        return {
          label: t("reportGridTabsLabels.missing"),
          description: t("reportGridTabsLabels.missingTooltipText", {
            date: dateFormatter(date, {
              dateStyle: "medium",
              timeStyle: "short",
            }),
          }),
          position: 4,
        };
      default:
        return {
          position: 5,
        };
    }
  }

  type DiffKeys = keyof ReportData["diffs"];
  // eslint-disable-next-line fp/no-mutating-methods
  const tabs = Object.keys(props.diffs)
    .map((diffKey) => {
      const tabProps = getDiffReport(mapStringToReportTypeCode(diffKey));
      return {
        label: tabProps.label,
        description: tabProps.description,
        totalRows: props.diffs[diffKey as DiffKeys],
        value: diffKey,
        position: tabProps.position,
        reportTypeCode: mapStringToReportTypeCode(diffKey),
      };
    })
    .sort((a, b) => a.position - b.position);

  if (props.mode === "hidden") {
    return null;
  }

  return (
    <div className={classes.conatainer}>
      <Tabs
        className={classes.tabs}
        value={tab}
        variant="standard"
        indicatorColor="primary"
        onChange={onChange}
      >
        {tabs.map(
          ({ label, value, totalRows, description, reportTypeCode }, i) => {
            const showTotal = tab === value && searchParams.has("filter");

            if (
              props.mode === "onlyTotalRowsVisible" &&
              reportTypeCode !== ReportTypeCode.Basic
            ) {
              return null;
            }

            return (
              <Tab
                key={i}
                value={value}
                disabled={!tabs.length}
                classes={{ root: classes.root }}
                label={
                  <Box className={classes.label}>
                    <span>{label}</span>
                    {props.loading ? (
                      <TabSpinner />
                    ) : (
                      <span className={classes.totalRows}>
                        ({formatter(totalRows === -1 ? 0 : totalRows)}
                        {showTotal && ` of ${formatter(props.totalRows)}`})
                      </span>
                    )}
                    {description ? (
                      <Tooltip title={description} arrow placement="top">
                        <InformationCircleOutlined className={classes.icon} />
                      </Tooltip>
                    ) : null}
                  </Box>
                }
                data-testid={`report-grid-tab-${value}`}
              ></Tab>
            );
          },
        )}
      </Tabs>
      <div className={classes.content}>{props.children}</div>
    </div>
  );
}
