/* eslint-disable fp/no-mutating-methods */
import {
  DragEndEvent,
  DragOverEvent,
  UniqueIdentifier,
  CollisionDetection,
  CollisionDescriptor,
  KeyboardCode,
  KeyboardCoordinateGetter,
  DroppableContainer,
  closestCorners,
  getFirstCollision,
  Announcements,
} from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { Task } from "../data/types";
import { LegacyTaskStatus } from "../../graphql";
import { useTranslation } from "@lumar/shared";
import { TFunction } from "i18next";

export type Items = Record<string, Task[]>;

export function handleDragOver(
  { active, over }: DragOverEvent,
  items: Items,
  setItems: (items: Items) => void,
): void {
  const overId = over?.id;
  if (!over || overId == null) return;

  const overContainer = findContainerId(items, overId);
  const activeContainer = findContainerId(items, active.id);

  if (!overContainer || !activeContainer || activeContainer === overContainer) {
    return;
  }

  const activeItems = items[activeContainer];
  const activeIndex = activeItems.findIndex((x) => x.id === active.id);

  const overItems = items[overContainer];
  const overIndex = overItems.findIndex((x) => x.id === overId);

  const newIndex = (() => {
    if (overId in items) {
      return 0;
    } else {
      const isBelowOverItem =
        active.rect.current.translated &&
        active.rect.current.translated.top > over.rect.top;

      const modifier = isBelowOverItem ? 1 : 0;

      return overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
    }
  })();

  setItems({
    ...items,
    [activeContainer]: items[activeContainer].filter((x) => x.id !== active.id),
    [overContainer]: [
      ...items[overContainer].slice(0, newIndex),
      items[activeContainer][activeIndex],
      ...items[overContainer].slice(newIndex, items[overContainer].length),
    ],
  });
}

export function handleDragEnd(
  { active, over }: DragEndEvent,
  items: Items,
  setItems: (items: Items) => void,
): void {
  const activeContainer = findContainerId(items, active.id);
  const overContainer = findContainerId(items, over?.id);

  if (!activeContainer || !overContainer) {
    setItems(items);
    return;
  }

  const activeIndex = items[activeContainer].findIndex(
    (x) => x.id == active.id,
  );
  const overIndex = items[overContainer].findIndex((x) => x.id === over?.id);

  if (activeIndex !== overIndex) {
    setItems({
      ...items,
      [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex),
    });
  } else {
    setItems(items);
  }
}

export const coordinateGetter: KeyboardCoordinateGetter = (
  event,
  { context: { active, droppableRects, droppableContainers, collisionRect } },
) => {
  if (event.code !== KeyboardCode.Up && event.code !== KeyboardCode.Down) {
    return;
  }

  event.preventDefault();

  if (!active || !collisionRect) {
    return;
  }

  const isUp = event.code === KeyboardCode.Up;
  const isDown = event.code === KeyboardCode.Down;
  const activeHeight = active.rect.current.initial?.height ?? 0;

  const filteredContainers: DroppableContainer[] = [];

  droppableContainers.getEnabled().forEach((entry) => {
    if (!entry || entry?.disabled) {
      return;
    }

    const rect = droppableRects.get(entry.id);

    if (!rect) {
      return;
    }

    const data = entry.data.current;

    if (data) {
      const { type, children } = data;

      if (type === "container" && children?.length > 0) {
        if (active.data.current?.type !== "container") {
          return;
        }
      }
    }

    switch (event.code) {
      case KeyboardCode.Down:
        if (collisionRect.top < rect.top) {
          filteredContainers.push(entry);
        }
        break;
      case KeyboardCode.Up:
        if (collisionRect.top > rect.top) {
          filteredContainers.push(entry);
        }
        break;
    }
  });

  const collisions = closestCorners({
    active,
    collisionRect: collisionRect,
    droppableRects,
    droppableContainers: filteredContainers,
    pointerCoordinates: null,
  });

  const closestId = getFirstCollision(collisions, "id");

  if (closestId != null) {
    const newDroppable = droppableContainers.get(closestId);
    const newNode = newDroppable?.node.current;
    const newRect = newDroppable?.rect.current;

    if (newNode && newRect) {
      if (newDroppable.data.current?.type === "container") {
        return {
          x: newRect.left,
          y: newRect.top + (isDown ? -activeHeight : 0),
        };
      }

      const isLast =
        newDroppable.data.current?.sortable?.items?.at(-1) === closestId;
      if (isUp && isLast) {
        return {
          x: newRect.left,
          y: newRect.top + activeHeight,
        };
      }

      const isFirst =
        newDroppable.data.current?.sortable?.items?.at(0) === closestId;
      if (isDown && isFirst) {
        return {
          x: newRect.left,
          y: newRect.top - activeHeight,
        };
      }

      return {
        x: newRect.left,
        y: newRect.top,
      };
    }
  }
};

export const closestCenter: CollisionDetection = ({
  collisionRect,
  droppableRects,
  droppableContainers,
}) => {
  const centerRect = centerOfRectangle(
    collisionRect,
    collisionRect.left,
    collisionRect.top,
  );
  const collisions: CollisionDescriptor[] = [];

  droppableContainers.forEach((droppableContainer) => {
    const { id } = droppableContainer;
    const rect = droppableRects.get(id);
    if (!rect) return;

    if (
      droppableContainer.data.current?.type === "container" &&
      droppableContainer.data.current?.children?.length
    ) {
      return;
    }

    const distBetween = verticalDistanceBetween(
      centerOfRectangle(rect),
      centerRect,
    );
    collisions.push({ id, data: { droppableContainer, value: distBetween } });
  });

  const result = collisions.sort((a, b) => a.data.value - b.data.value);
  return result.length ? [result[0]] : [];
};

export function getPosition(
  items: Items,
  activeId: UniqueIdentifier | null,
): [LegacyTaskStatus, number] | undefined {
  return (Object.keys(items) as LegacyTaskStatus[]).reduce<
    [LegacyTaskStatus, number] | undefined
  >((result, status) => {
    const tasks = items[status] || [];
    const idx = tasks.findIndex((x) => x.id === activeId);

    if (idx === -1) return result;

    if (idx === 0 && tasks.length === 1) {
      return [status, 1];
    }

    if (idx === 0) {
      const currentPos = tasks[idx].position ?? 1;
      const newPos = tasks[idx + 1].position ?? 1;
      return [status, currentPos < newPos ? newPos - 1 : newPos];
    }

    const currentPos = tasks[idx].position ?? 1;
    const newPos = tasks[idx - 1].position ?? 1;
    return [status, currentPos > newPos ? newPos + 1 : newPos];
  }, undefined);
}

export function useAnnouncements(items: Items): Announcements {
  const { t } = useTranslation("taskManager");

  function findTask(
    taskId: UniqueIdentifier,
  ): [Task | undefined, string | undefined] {
    const task = (Object.keys(items) as LegacyTaskStatus[]).reduce<
      [Task, LegacyTaskStatus] | undefined
    >((res, status) => {
      const task = items[status].find((x) => x.id === taskId);
      return task ? [task, status] : res;
    }, undefined);

    return [task?.[0], getStatusName(t, task?.[1])];
  }

  return {
    onDragStart({ active }) {
      const [task] = findTask(active.id);
      return t("dnd.pickedUp", { name: task?.title });
    },
    onDragOver({ active, over }) {
      const [task, status] = findTask(active.id);
      if (over?.id) {
        const position = over.data.current?.sortable?.index ?? 0;

        return t("dnd.movedTo", {
          name: task?.title,
          position: position + 1,
          status: status,
        });
      }

      return t("dnd.notIn", { name: task?.title });
    },
    onDragEnd({ active, over }) {
      const [task, status] = findTask(active.id);
      if (over?.id) {
        const position = over.data.current?.sortable?.index ?? 0;

        return t("dnd.droppedTo", {
          name: task?.title,
          position: position + 1,
          status: status,
        });
      }

      return t("dnd.dropped", { name: task?.title });
    },
    onDragCancel({ active }) {
      const [task] = findTask(active.id);
      return t("dnd.cancel", { name: task?.title });
    },
  };
}

function findContainerId(
  items: Items,
  id: UniqueIdentifier | undefined,
): string | undefined {
  if (id && id in items) {
    return id as LegacyTaskStatus;
  }

  return (Object.keys(items) as LegacyTaskStatus[]).find((key) =>
    items[key].find((x) => x.id === id),
  );
}

function centerOfRectangle(
  rect: {
    width: number;
    height: number;
    top: number;
    left: number;
    right: number;
    bottom: number;
  },
  left = rect.left,
  top = rect.top,
): { x: number; y: number } {
  return {
    x: left + rect.width * 0.5,
    y: top + rect.height * 0.5,
  };
}

function verticalDistanceBetween(
  p1: { x: number; y: number },
  p2: { x: number; y: number },
): number {
  return Math.abs(p1.y - p2.y);
}

function getStatusName(
  t: TFunction<"taskManager">,
  status?: LegacyTaskStatus,
): string | undefined {
  switch (status) {
    case LegacyTaskStatus.Done:
      return t("status.done");
    case LegacyTaskStatus.Testing:
      return t("status.testing");
    case LegacyTaskStatus.InProgress:
      return t("status.inProgress");
    case LegacyTaskStatus.ToDo:
      return t("status.toDo");
    case LegacyTaskStatus.Backlog:
      return t("status.backlog");
    default:
      return status;
  }
}
