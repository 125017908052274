import { Routes } from "../../routing/routes";

export function getCruxSummaryLink(
  accountId: string,
  projectId: string,
  crawlId: string,
): string {
  return Routes.Report.getUrl({
    accountId,
    projectId,
    crawlId,
    reportTemplateCode: "crux_domain_summary",
    reportTypeCode: "basic",
  });
}
