import { createSvgIcon } from "@lumar/shared";

export const PriorityLowIcon = createSvgIcon(
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 7.39396L4.84322 6.58333L10.0079 11.4025L10.5587 10.8884L10.5557 10.8912L15.1441 6.60932L16 7.40808C14.7318 8.59154 11.1913 11.8956 10.0079 13C9.12833 12.1798 9.98547 12.9797 4 7.39396Z"
      fill="#3B82F6"
    />
    <path
      d="M4 7.39396L4.84322 6.58333L10.0079 11.4025L10.5587 10.8884L10.5557 10.8912L15.1441 6.60932L16 7.40808C14.7318 8.59154 11.1913 11.8956 10.0079 13C9.12833 12.1798 9.98547 12.9797 4 7.39396"
      stroke="#3B82F6"
      strokeWidth="0.5"
      fill="none"
    />
  </>,
  "PriorityLowIcon",
  { viewBox: "0 0 20 20" },
);
