import { createSvgIcon } from "@lumar/shared";

export const PriorityMediumIcon = createSvgIcon(
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 9H16V7H3V9Z"
      fill="#FB923C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 14H16V12H3V14Z"
      fill="#FB923C"
    />
  </>,
  "PriorityMediumIcon",
  { viewBox: "0 0 20 20" },
);
