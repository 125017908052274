/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { ApolloError, DocumentNode, gql, useQuery } from "@lumar/shared";
import { generateQuery } from "./generateMetricFields";
import { jsonToGraphQLQuery, VariableType } from "json-to-graphql-query";
import { ReportTypeCode } from "../../graphql";
import { formatMetrics } from "./formatMetrics";
import { DataExplorerRow, DataExplorerTableConfig } from "../types";

interface Props {
  crawlId: string;
  tableConfig: DataExplorerTableConfig;
}

interface Result {
  rows: DataExplorerRow[];
  loading: boolean;
  error?: ApolloError;
  hasSegments: boolean;
}

export function useSegmentsData({ crawlId, tableConfig }: Props): Result {
  const {
    loading,
    fetchMore,
    error: queryError,
    data,
  } = useQuery(getDocument(tableConfig), {
    variables: { crawlId },
    fetchPolicy: "cache-first",
    errorPolicy: "all",
    onError: () => null,
  });

  // Note: The fetchMore function does not populate the error
  // field in the useQuery hook.
  const [fetchMoreError, setFetchMoreError] = React.useState<
    ApolloError | undefined
  >();
  React.useEffect(() => {
    if (loading && fetchMoreError) {
      setFetchMoreError(undefined);
    }
  }, [fetchMoreError, loading]);

  const error = queryError || fetchMoreError;

  const hasNextPage = Boolean(data?.getCrawl?.reports?.pageInfo.hasNextPage);
  const cursor = data?.getCrawl?.reports?.pageInfo.endCursor;

  React.useEffect(() => {
    if (hasNextPage && cursor) {
      fetchMore({ variables: { cursor } })
        .then(
          ({ errors }) =>
            errors?.length &&
            setFetchMoreError(new ApolloError({ graphQLErrors: errors })),
        )
        .catch((error) => setFetchMoreError(error));
    }
  }, [cursor, hasNextPage, fetchMore]);

  const dataRef = React.useRef<any>();
  if (data && !(hasNextPage && !error)) {
    dataRef.current = data;
  }

  const aggregateData = dataRef.current;

  const rows =
    aggregateData?.getCrawl?.reports?.edges
      ?.filter((report: any) => report.node.crawlUrlsAggregates)
      ?.map((report: any) => ({
        segment: report.node.segment.name,
        segmentId: report.node.segment.id,
        ...formatMetrics(report.node.crawlUrlsAggregates[0]),
      })) || [];

  return {
    loading: aggregateData ? false : !error,
    error,
    rows,
    hasSegments: aggregateData?.getCrawl?.project?.segments.totalCount > 0,
  };
}

function getDocument(tableConfig: DataExplorerTableConfig): DocumentNode {
  const metricFields = generateQuery(tableConfig, false);

  const query = {
    query: {
      __name: "getAggregateData",
      __variables: {
        crawlId: "ObjectID!",
        cursor: "String",
      },
      getCrawl: {
        __args: {
          id: new VariableType("crawlId"),
        },
        id: true,
        reports: {
          __args: {
            first: 100,
            after: new VariableType("cursor"),
            filter: {
              datasourceCode: { eq: "crawl_urls" },
              reportTemplateCode: { eq: "all_pages" },
              reportTypeCode: { eq: ReportTypeCode.Basic.toLowerCase() },
              segmentId: { isNull: false },
            },
          },
          edges: {
            node: {
              id: true,
              crawlUrlsAggregates: {
                ...metricFields,
              },
              segment: {
                id: true,
                name: true,
              },
            },
          },
          pageInfo: {
            endCursor: true,
            hasNextPage: true,
          },
        },
        project: {
          id: true,
          segments: {
            totalCount: true,
          },
        },
      },
    },
  };

  return gql`
    ${jsonToGraphQLQuery(query, {
      pretty: true,
    })}
  `;
}
